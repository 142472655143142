import type { SxProps } from '@mui/material';
import styled, { css } from 'styled-components';
import { palette } from 'workmotion-design-system';

import { StyledDropdownItemProps } from '.';

export const StyledDropdown = styled.div({
  position: 'relative',
  cursor: 'pointer',
  width: 'max-content',
});

export const popoverStyle: SxProps = {
  '& .MuiPopover-paper': {
    borderRadius: '8px',
    color: palette.greyscale.UCLABlue,
    boxShadow: '0px 0px 16px rgba(82, 95, 127, 0.239216)',
    padding: '0.5rem 0',
  },
};

export const StyledDropdownItem = styled.div<StyledDropdownItemProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.75rem 1rem;
  gap: 0.5rem;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 0.875rem;
  min-width: max-content;
  width: 100%;
  cursor: pointer;

  :hover {
    background-color: ${palette.greyscale.antiFlashWhite};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.7;
      cursor: not-allowed;
    `}
`;
