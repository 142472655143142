import React, { useCallback, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Button, LayoutWrapper, Spinner } from 'workmotion-design-system';

import {
  AuthenticatedIdentity,
  useUserIdentity,
} from 'authentication/use-user-identity';
import { en } from 'localization';
import { useActiveEquipmentRequests } from 'networking/equipment-api/equipment-requests';
import { AddressDTO, EmployeeResponseDTO } from 'types/employee';
import { EquipmentQueryParameters } from 'types/equipment-types';

import { AddressSummary } from './address-summary';
import { addressInitialValues, addressSchema } from './formik-values-setup';
import { Card } from './styled-component';
import { TalentNameField } from '../equipment-details-step/formik-talent-name';
import { LayoutCaption } from '../equipment-details-step/layout-caption';
import {
  Description,
  Title,
} from '../equipment-details-step/request-equipment-styled';
import { Content } from '../layout-content';
import { Footer } from '../place-order-step/place-order-styled';

export const DeliveryAddressStep: React.FC = () => {
  const {
    requestEquipment: {
      buttons: { primaryButton, secondryButton },
      layoutTitle,
      wrapperTitle,
      layoutDescription,
      firstStepDescription,
    },
  } = en;

  const navigate = useNavigate();

  const { companyId: userCompanyId } =
    useUserIdentity() as AuthenticatedIdentity;

  const [employee, setEmployee] = useState<EmployeeResponseDTO>(null);

  const [talentDeleviryAddress, setTalentDeliveryAddress] =
    useState<AddressDTO>();

  useEffect(() => {
    setTalentDeliveryAddress(employee?.address);
  }, [employee]);

  const [requestParams, setRequestParams] =
    useState<EquipmentQueryParameters>(null);

  const { data: talentPerviousRequests } =
    useActiveEquipmentRequests(requestParams);

  const setAddress = useCallback((address: AddressDTO) => {
    setTalentDeliveryAddress(address);
  }, []);

  const [supportedCountry, setSupportedCountry] = useState<boolean>(true);

  const getSupportedCountry = useCallback(value => {
    setSupportedCountry(value);
  }, []);

  useEffect(() => {
    if (employee) {
      setRequestParams({
        page: 0,
        size: 1,
        talentId: employee?.id,
        companyId: userCompanyId || null,
      });
    }
  }, [employee, userCompanyId]);

  const submitForm = useCallback(() => {
    navigate('/equipment/request-equipment/details', {
      state: {
        selectedEmployee: employee,
        talentAddress: talentDeleviryAddress,
      },
    });
  }, [employee, navigate, talentDeleviryAddress]);

  return (
    <LayoutWrapper
      layoutCaption={
        <LayoutCaption title={layoutTitle} description={layoutDescription} />
      }
      onLogoClick={() => navigate(-1)}
    >
      <Box sx={{ width: '28.75rem' }}>
        <Title data-cy="request-equipment-title-step1">{wrapperTitle}</Title>

        <Description>{firstStepDescription}</Description>
      </Box>

      <Formik
        initialValues={addressInitialValues()}
        onSubmit={submitForm}
        validationSchema={addressSchema}
      >
        {({ isSubmitting }) => (
          <>
            <Content direction={'center'}>
              <Form style={{ width: '100%', marginBottom: '2rem' }} role="form">
                <Card>
                  <TalentNameField
                    setEmployee={setEmployee}
                    isEditing
                    selectedEmployee={employee}
                  />
                </Card>

                {employee && (
                  <AddressSummary
                    talent={employee}
                    setTalentAddress={setAddress}
                    setSupportedCountry={getSupportedCountry}
                    talentHasPerviousRequests={
                      talentPerviousRequests?.content?.length > 0
                    }
                  />
                )}

                <Footer>
                  <Button
                    btnType={'secondaryGrey'}
                    onClick={() => navigate(-1)}
                    data-cy="cancel-btn"
                  >
                    {secondryButton}
                  </Button>

                  <Button
                    btnType={supportedCountry ? 'default' : 'subtle'}
                    type={'submit'}
                    data-cy="submit-btn"
                    disabled={!supportedCountry}
                  >
                    {!isSubmitting && primaryButton}

                    {isSubmitting && <Spinner size={20} />}
                  </Button>
                </Footer>
              </Form>
            </Content>
          </>
        )}
      </Formik>
    </LayoutWrapper>
  );
};
