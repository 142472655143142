import React from 'react';

import {
  CountryFlag,
  palette,
  Table as TableComponent,
} from 'workmotion-design-system';

import { ActionItemsCellContainer } from 'app/shared/table/styled-components';
import { en } from 'localization';
import {
  ActiveStateStatusEnum,
  EquipmentResponseDTO,
} from 'types/equipment-types';

import { ActiveActions } from '../../cells/actions';
import { EquipmentNameCell } from '../../cells/cell';
import { DateCell } from '../../cells/date-cell';
import { ActiveStatusColors } from '../../cells/status-colors';
import { StatusHeader } from '../../cells/status-header';
import { TalentName } from '../../cells/talent-name';
import { ActiveTimelineStatus } from '../../cells/timeline-status';

const {
  managedView: {
    activeTable: { columns, statusLegend },
  },
} = en;

const availableActiveStatuses = Object.values(ActiveStateStatusEnum);

export const ActiveTable: React.FC<{
  equipmentList: EquipmentResponseDTO[];
  setSelectedEquipment: (selected: EquipmentResponseDTO) => void;
  openPreviewModal: (isOpen: boolean) => void;
  openReturnModal: (isOpen: boolean) => void;
}> = ({
  equipmentList,
  setSelectedEquipment,
  openPreviewModal,
  openReturnModal,
}) => {
  const tableColumns = columns.map(column =>
    column === 'STATUS'
      ? {
          cell: (
            <StatusHeader
              columnName={column}
              statusLegend={statusLegend}
              availableStatuses={availableActiveStatuses}
              statusColors={ActiveStatusColors}
            />
          ),
        }
      : { cell: <div style={{ paddingRight: '0.5rem' }}>{column}</div> }
  );

  const tableRows = equipmentList.map(equipment => {
    const {
      equipmentId: id,
      talent,
      countryIsoCode,
      orderedAt,
      expiryDate,
      equipmentName,
      activeStateStatus,
    } = equipment;

    return {
      id,
      cells: [
        <TalentName key={id} talent={talent} />,
        <CountryFlag
          key={id}
          countryIsoCode={countryIsoCode}
          customStyledFlagIcon={{
            width: '1.5rem',
            height: '1.5rem',
            borderRadius: '50%',
          }}
        />,
        <DateCell key={id} date={orderedAt}></DateCell>,
        <DateCell key={id} date={expiryDate}></DateCell>,
        <EquipmentNameCell key={id}>{equipmentName}</EquipmentNameCell>,
        <ActiveTimelineStatus key={id} status={activeStateStatus} />,
        <ActionItemsCellContainer key={id}>
          <ActiveActions
            handlePreview={() => {
              setSelectedEquipment(equipment);
              openPreviewModal(true);
            }}
            showReturn={activeStateStatus === ActiveStateStatusEnum.DELIVERED}
            handleReturn={() => {
              setSelectedEquipment(equipment);
              openReturnModal(true);
            }}
          />
        </ActionItemsCellContainer>,
      ],
    };
  });

  return (
    <TableComponent
      backgroundColor={`${palette.secondary.white}`}
      columns={tableColumns}
      rows={tableRows}
      withHover
    />
  );
};
