import React from 'react';

import { Box } from '@mui/material';

import {
  Card,
  LayoutDescription,
  LayoutTitle,
} from './request-equipment-styled';
import { SelectedProductsContext } from './selected-products-context';
import { EquipmentCard } from '../../shared/equipment-item';

interface LayputCaptionProps {
  title: string;
  description: string;
}

export const LayoutCaption: React.FC<LayputCaptionProps> = ({
  title,
  description,
}) => (
  <>
    <LayoutTitle>{title}</LayoutTitle>

    <LayoutDescription>{description}</LayoutDescription>

    <SelectedProductsContext.Consumer>
      {value =>
        value.products.length > 0 && (
          <>
            <p style={{ fontSize: '12px', margin: '1rem 0' }}>
              SELECTED EQUIPMENT
            </p>

            <Box
              sx={{
                marginBottom: '2rem',
                height: '50vh',
                overflow: 'scroll',
                marginRight: '0.5rem',
                '&::-webkit-scrollbar-corner': { background: 'transparent' },
              }}
            >
              {value.products.map(selectedProduct => (
                <Card
                  key={selectedProduct.id}
                  style={{
                    padding: 0,
                    width: '100%',
                    marginBottom: '0.75rem',
                  }}
                >
                  <EquipmentCard
                    showCheckBox={true}
                    hasError={false}
                    selected={true}
                    option={selectedProduct}
                    onChangeTextBox={() =>
                      value.deselectProduct(selectedProduct)
                    }
                  />
                </Card>
              ))}
            </Box>
          </>
        )
      }
    </SelectedProductsContext.Consumer>
  </>
);
