import React from 'react';

import { Box } from '@mui/material';
import { Checkbox } from 'workmotion-design-system';

import { EquipmentProductDTO } from 'types/equipment-types';

import {
  ItemContainer,
  Period,
  ProductImage,
  ProductName,
  ProductPrice,
  ProductPriceContainer,
} from './styled-component';
import { en } from '../../../localization';
import { getItemPrice } from '../../request-equipment/equipment-details-step/mapping-helper';

export interface Option {
  label?: string;
  value?: string;
  imageSrc?: string;
  description?: string;
  descriptionHint?: string;
  id?: string;
  checked?: boolean;
  category?: string;
}

export interface ItemProps {
  option?: EquipmentProductDTO;
  selected?: boolean;
  showCheckBox: boolean;
  hasError: boolean;
  onChangeTextBox?: (e: React.MouseEvent<HTMLElement>) => void;
}

const {
  requestEquipment: { paidMonthly },
} = en;

export const EquipmentCard: React.FC<ItemProps> = ({
  option,
  selected,
  showCheckBox = true,
  hasError,
  onChangeTextBox = () => {},
}) => (
  <ItemContainer onClick={onChangeTextBox}>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        gap: '15px',
        alignItems: 'center',
        flexShrink: 1,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      }}
    >
      {showCheckBox && (
        <Checkbox
          checked={selected}
          onChange={onChangeTextBox as never}
          checkboxSize={18}
          checkboxLogoStyle={hasError ? { border: '1px solid red' } : null}
          id={option.id}
          data-cy={option.name}
        />
      )}

      {option.images && option.images[0] && (
        <ProductImage src={option.images[0]} alt={option.name} />
      )}

      {option.name && <ProductName>{option.name}</ProductName>}
    </Box>

    <ProductPriceContainer>
      {option.variants && (
        <ProductPrice>{getItemPrice(option.variants)}</ProductPrice>
      )}

      <Period>{paidMonthly}</Period>
    </ProductPriceContainer>
  </ItemContainer>
);
