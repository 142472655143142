import React, { useState } from 'react';

import { EmployeeResponseDTO } from 'types/employee';

import { SearchAutocomplete } from './search-autocomplete';
import { useSearchTalents } from './use-search-talents';

interface TalentSearchProps {
  onSelect?: (employee: EmployeeResponseDTO) => void;
  onRemove?: () => void;
  selectedTalent?: EmployeeResponseDTO;
  isDisabled?: boolean;
  showError?: boolean;
  withSearchIcon?: boolean;
}

export const TalentSearch: React.FC<TalentSearchProps> = ({
  onSelect,
  onRemove,
  withSearchIcon = false,
  selectedTalent = null,
  isDisabled = false,
  showError = false,
}) => {
  const [searchValue, setSearchValue] = useState<string>('');

  const { isLoading, talents, getNextPage } = useSearchTalents(searchValue);

  return (
    <SearchAutocomplete
      onSelect={onSelect}
      onRemove={onRemove}
      options={talents}
      selectedTalent={selectedTalent}
      isDisabled={isDisabled}
      setSearchValue={setSearchValue}
      loadMoreOptions={getNextPage}
      isLoading={isLoading}
      withSearchIcon={withSearchIcon}
      showError={showError}
    />
  );
};
