import React, { useCallback } from 'react';

import { useField } from 'formik';
import { InputField, FormField } from 'workmotion-design-system';

import { en } from 'localization';

const {
  requestEquipment: {
    deliveryAddress: {
      formFields: {
        addressLine2: { fieldName, label, placeholder },
      },
    },
  },
} = en;

export const AddressLine2Field: React.FC = () => {
  const [field, meta, { setValue }] = useField(fieldName);
  const showError = !!(meta.touched && meta.error);

  const onTextChange = useCallback(
    text => {
      setValue(text.target.value);
    },
    [setValue]
  );

  return (
    <FormField label={label} showError={showError} errorMessage={meta.error}>
      <InputField
        {...field}
        onChange={onTextChange}
        placeholder={placeholder}
        showError={showError}
        errorMessage={meta.error}
        data-testid="AddressLine2-textbox"
      />
    </FormField>
  );
};
