import { useMemo } from 'react';

export const useSelectedCompanyId = (): { selectedCompanyId: string } => {
  const storeState = (window as any).store.getState();

  const selectedCompanyId = useMemo(
    () => storeState.currentCompany.id,
    [storeState]
  );

  return { selectedCompanyId };
};
