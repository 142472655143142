import React from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box } from '@mui/material';

import { Dropdown, DropdownItemProps } from './dropdown';
import { actionButtonStyle, actionIconStyle } from './styled-components';

interface ActionDropdownProps {
  items: DropdownItemProps[];
}

const DropdownController: React.FC = () => (
  <Box sx={actionButtonStyle}>
    <MoreVertIcon sx={actionIconStyle} />
  </Box>
);

export const ActionDropdown: React.FC<ActionDropdownProps> = ({ items }) => (
  <Dropdown dropdownController={<DropdownController />} dropdownItems={items} />
);
