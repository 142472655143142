import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

export const StyledSideMenuContainer = styled.div({
  padding: '0 2rem 2rem 2rem',
});

export const StyledInfoBox = styled.div({
  padding: '1rem 0',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  borderBottom: `1px solid ${[palette.greyscale.antiFlashWhite]}`,
  '&:last-child': {
    borderBottom: 'none',
  },
  fontFamily: 'Work Sans',
  fontStyle: 'normal',
  fontWeight: 300,
  fontSize: '0.875rem',
  lineHeight: '1.375rem',
  color: palette.greyscale.UCLABlue,
});
