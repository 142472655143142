import React from 'react';

import LinkRoundedIcon from '@mui/icons-material/LinkRounded';

import { en } from 'localization';

import { linkStyle } from './styled-components';

const {
  managedView: {
    shipmentPreview: { trackLink },
  },
} = en;

export const TrackingLink: React.FC<{ link: string }> = ({ link }) =>
  link ? (
    <a
      data-testid="tracking-link"
      href={link}
      style={linkStyle}
      target="_blank"
      rel="noreferrer"
    >
      <LinkRoundedIcon />

      {trackLink}
    </a>
  ) : (
    <>-</>
  );
