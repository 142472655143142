import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

export const StyledFilterPanel = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '1.5rem',
  backgroundColor: palette.secondary.white,
  marginTop: '2rem',
  gap: '1rem',

  '@media(max-width:600px)': {
    flexDirection: 'column',
    gap: '0.5rem',
  },
});

export const TalentSearchWrapper = styled.div({
  width: '412px',

  '@media(max-width:600px)': {
    width: '100%',
  },
});
