import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

export const PreviewItemContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '1.5rem 0',
  borderBottom: `1px solid ${palette.greyscale.antiFlashWhite}`,
  '&:last-child': {
    borderBottom: 'none',
  },
});

export const columnViewContainerStyle: React.CSSProperties = {
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: '100%',
  alignItems: 'flex-start',
  gap: '0.5rem',
};

export const PreviewValue = styled.div({
  lineHeight: '1rem',
  fontWeight: 500,
  fontSize: '0.875rem',
  fontFamily: 'Work Sans',
  textAlign: 'right',
  color: palette.greyscale.persianBlue,
});

export const PreviewLabel = styled.div({
  lineHeight: '1rem',
  fontSize: '0.875rem',
  fontFamily: 'Work Sans',
  marginRight: 24,
  fontWeight: 400,
  color: palette.greyscale.UCLABlue,
});

export const linkStyle = {
  display: 'flex',
  gap: '0.25rem',
  alignItems: 'center',
  textDecoration: 'none',

  color: palette.primary.genericViridian,
  ':hover': {
    color: palette.primary.midnightGreen,
  },
};
