import React, { useCallback } from 'react';

import { useField } from 'formik';
import { FormField, InputField } from 'workmotion-design-system';

import { en } from 'localization';

const {
  requestEquipment: {
    deliveryAddress: {
      formFields: {
        addressLine1: { fieldName, label, placeholder },
      },
    },
  },
} = en;

export const AddressLine1Field: React.FC = () => {
  const [field, meta, { setValue }] = useField(fieldName);

  const onTextChange = useCallback(
    text => {
      setValue(text.target.value);
    },
    [setValue]
  );

  const showError = !!(meta.touched && meta.error);

  return (
    <FormField label={label} showError={showError} errorMessage={meta.error}>
      <InputField
        {...field}
        onChange={onTextChange}
        placeholder={placeholder}
        showError={showError}
        data-testid="AddressLine1-textbox"
      />
    </FormField>
  );
};
