import React, { useCallback, FC, useState } from 'react';

import { FiltersMenu } from 'app/shared/filters-menu';

import { CategoriesList, CategoriesListProps } from './categories-list';

interface EquipmentFilterMenuProps extends CategoriesListProps {
  onResetFilter: () => void;
  onApplyFilter: (value: string) => void;
  category: string;
}

const EquipmentFilterMenu: FC<EquipmentFilterMenuProps> = ({
  options,
  onResetFilter,
  onApplyFilter,
  category,
}) => {
  const [selectedCategory, setSelectedCategory] = useState<string>(category);

  const resetFilters = useCallback(() => {
    onResetFilter();
    setSelectedCategory(null);
  }, [onResetFilter]);

  const applyFilter = useCallback(() => {
    onApplyFilter(selectedCategory);
  }, [onApplyFilter, selectedCategory]);

  const onCategorySelected = useCallback((selectedValue: string) => {
    setSelectedCategory(selectedValue);
  }, []);

  const closeFilters = useCallback(() => {
    if (category) setSelectedCategory(category);
    if (!category) resetFilters();
  }, [category, resetFilters]);

  return (
    <FiltersMenu
      onReset={resetFilters}
      onApply={applyFilter}
      onClose={closeFilters}
      dataCyReset={'categories-reset'}
      dataCyApply={'categories-apply'}
    >
      <CategoriesList
        options={options}
        onValueSelected={onCategorySelected}
        value={selectedCategory}
      />
    </FiltersMenu>
  );
};

export { EquipmentFilterMenu };
