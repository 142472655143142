import React, { useCallback, useState } from 'react';

import type { SelectOption } from 'workmotion-design-system';
import { Button } from 'workmotion-design-system';

import {
  Period,
  ProductPrice,
  ProductPriceContainer,
} from 'app/shared/equipment-item/styled-component';
import { en } from 'localization';
import {
  EquipmentOrderItemDTO,
  EquipmentProductDTO,
  EquipmentProductVariantDTO,
  EquipmentProductVariantPricingDTO,
} from 'types/equipment-types';

import {
  Container,
  ProductBrand,
  ProductImage,
  ProductInfo,
  ProductInfoAndPiceContainer,
  ProductName,
  ProductNameAndBrandContainer,
} from './item-card-styled';
import { RentalPeriodField } from './rental-period-select';
import { VariantSelectField } from './varaint-select';

interface EquipmentCardItemProps {
  productItem?: EquipmentProductDTO;
  onRemoveItem?: (
    product: EquipmentProductDTO,
    selectedVariant?: EquipmentProductVariantDTO
  ) => void;
  onSelectVariantAndRentalPeriod?: (
    orderItem: EquipmentOrderItemDTO,
    productItem: EquipmentProductDTO,
    cost?: string
  ) => void;
}

export const EquipmentCardItem: React.FC<EquipmentCardItemProps> = ({
  productItem,
  onRemoveItem,
  onSelectVariantAndRentalPeriod,
}) => {
  const [selectedVariant, setSelectVariant] =
    useState<EquipmentProductVariantDTO>(null);

  const [priceItem, setPricingItem] =
    useState<EquipmentProductVariantPricingDTO>();

  const {
    requestEquipment: {
      paidMonthly,
      placeOrderStep: { deleteButton },
    },
  } = en;

  const removeVarinat = useCallback(() => {
    onRemoveItem(productItem, selectedVariant);
  }, [onRemoveItem, productItem, selectedVariant]);

  const mappingFromPricingListToVarintOptions = useCallback(
    (equipmentItem: EquipmentProductDTO): SelectOption[] =>
      equipmentItem.variants.map(variantItem => ({
        label: variantItem?.label,
        value: variantItem?.id,
      })),
    []
  );

  const mappingFromPricingListToRentalOptions = useCallback(
    (varintItem: EquipmentProductVariantDTO): SelectOption[] =>
      varintItem.pricing.map(pricingItem => ({
        label: `${pricingItem.rentalTermMonths} months`,
        value: pricingItem.rentalTermMonths.toString(),
      })),
    []
  );

  const onVariantChangesChanges = useCallback(
    (selectedValue: string): void => {
      setSelectVariant(
        productItem.variants.find(item => item.id === selectedValue)
      );

      if (priceItem?.rentalTermMonths && selectedVariant?.id) {
        onSelectVariantAndRentalPeriod(
          {
            variantId: selectedVariant?.id,
            rentalPeriodMonths: priceItem?.rentalTermMonths,
          },
          productItem,
          priceItem?.amount.toString()
        );
      }
    },
    [
      onSelectVariantAndRentalPeriod,
      priceItem?.amount,
      priceItem?.rentalTermMonths,
      productItem,
      selectedVariant?.id,
    ]
  );

  const onRentalMonthChanges = useCallback(
    (selectedRentalPeriod: string): void => {
      const pricingItem = selectedVariant.pricing.find(
        item => item.rentalTermMonths === parseInt(selectedRentalPeriod)
      );

      setPricingItem(pricingItem);

      onSelectVariantAndRentalPeriod(
        {
          variantId: selectedVariant?.id,
          rentalPeriodMonths: parseInt(selectedRentalPeriod),
        },
        productItem,
        pricingItem?.amount.toString()
      );
    },
    [
      onSelectVariantAndRentalPeriod,
      productItem,
      selectedVariant?.id,
      selectedVariant?.pricing,
    ]
  );

  return (
    <Container data-testid="EquipmentCard">
      <ProductInfoAndPiceContainer>
        <ProductInfo>
          <ProductImage
            src={selectedVariant?.image}
            alt={selectedVariant?.label}
          />

          <ProductNameAndBrandContainer>
            <ProductName>{productItem.name}</ProductName>

            <ProductBrand>{productItem.brand}</ProductBrand>
          </ProductNameAndBrandContainer>
        </ProductInfo>

        <ProductPriceContainer>
          <ProductPrice>€ {priceItem?.amount}</ProductPrice>

          <Period>{paidMonthly}</Period>
        </ProductPriceContainer>
      </ProductInfoAndPiceContainer>

      <VariantSelectField
        options={mappingFromPricingListToVarintOptions(productItem)}
        onSelect={onVariantChangesChanges}
      />

      {selectedVariant && (
        <RentalPeriodField
          options={mappingFromPricingListToRentalOptions(selectedVariant)}
          onSelect={onRentalMonthChanges}
        />
      )}

      <Button
        btnType="fairPink"
        style={{ width: '100%' }}
        data-cy={`${productItem.name.replaceAll(' ', '-')}-delete-item-btn`}
        onClick={removeVarinat}
      >
        {deleteButton}
      </Button>
    </Container>
  );
};
