import { useCallback } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { en } from 'localization';
import { RequestError } from 'types';
import {
  EquipmentOrderItemDTO,
  EquipmentQueryParameters,
  EquipmentReturnRequest,
  GetEquipmentQueryParameters,
  OrderEquipmentDeliveryAddressDTO,
  EquipmentProductDTO,
} from 'types/equipment-types';

import {
  getActiveEquipmentList,
  getTalentEquipmentList,
  orderEquipmentsForTalent,
  requestReturnEquipment,
  getEquipmentReport,
  getSupportedCountries,
  getDeliveryAddress,
} from './equipment-client';
import { useAccessToken } from '../../authentication/use-access-token';

const {
  generic: { genricError, demoCompanyError },
  requestEquipment: { successMessage },
  managedView: {
    returnEquipment: { successMessage: returnSuccessMessage },
  },
} = en;

export const useActiveEquipmentRequests = (
  params: EquipmentQueryParameters
) => {
  const { accessToken } = useAccessToken();

  const getActiveEquipment = useCallback(
    async () => getActiveEquipmentList(params, accessToken.accessToken),

    [accessToken.accessToken, params]
  );

  const { data, isLoading } = useQuery({
    queryKey: ['EquipmentList', params],
    queryFn: getActiveEquipment,
    keepPreviousData: true,
    enabled: !!params,
  });

  return { data, isLoading };
};

export const useTalentEquipment = (talentId: string, countryCode: string) => {
  const { accessToken } = useAccessToken();

  const getTalentEquipment = useCallback(
    async () =>
      (
        await getTalentEquipmentList(
          talentId,
          countryCode,
          accessToken.accessToken
        )
      ).content,

    [accessToken.accessToken, countryCode, talentId]
  );

  const transformData = useCallback(
    data =>
      data.map((product: EquipmentProductDTO, index) => ({
        ...product,
        id: `${product.name}-${index}`,
      })),
    []
  );

  return useQuery({
    queryKey: ['equipmentTalentList', talentId],
    queryFn: getTalentEquipment,
    enabled: !!talentId,
    select: transformData,
  });
};

export const useGetEquipmentReport = () => {
  const { accessToken } = useAccessToken();

  const generateEquipmentReport = useCallback(
    async (params: GetEquipmentQueryParameters) =>
      getEquipmentReport(params, accessToken.accessToken),

    [accessToken.accessToken]
  );

  return useMutation({
    mutationFn: generateEquipmentReport,
  });
};

export const useRequestReturnEquipment = () => {
  const { accessToken } = useAccessToken();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const mutationFn = useCallback(
    async ({
      equipmentId,
      body,
    }: {
      equipmentId: string;
      body: EquipmentReturnRequest;
    }) => requestReturnEquipment(equipmentId, body, accessToken.accessToken),

    [accessToken.accessToken]
  );

  return useMutation({
    mutationKey: ['returnEquipment'],
    mutationFn,

    onSuccess: () => {
      toast(returnSuccessMessage, {
        type: 'success',
      });

      queryClient.invalidateQueries(['EquipmentList']);
      navigate('/equipment/return');
    },
  });
};

export const useTalentEquipmentOrdering = (
  talentId: string,
  orderItems: EquipmentOrderItemDTO[],
  deliveryAddress: OrderEquipmentDeliveryAddressDTO
) => {
  const navigate = useNavigate();

  const { accessToken } = useAccessToken();
  const queryClient = useQueryClient();

  const mutationFn = useCallback(
    async () =>
      orderEquipmentsForTalent(
        talentId,
        orderItems,
        deliveryAddress,
        accessToken.accessToken
      ),

    [accessToken.accessToken, deliveryAddress, orderItems, talentId]
  );

  return useMutation({
    mutationKey: ['OrderEquipment', talentId],
    mutationFn,
    onError: (error: RequestError) => {
      if (error.response.status === 422) {
        toast(demoCompanyError, {
          type: 'error',
        });
      } else {
        toast(genricError, {
          type: 'error',
        });
      }

      navigate('/equipment');
    },
    onSuccess: () => {
      toast(successMessage, {
        type: 'success',
      });

      queryClient.invalidateQueries(['EquipmentList']);

      navigate('/equipment');
    },
  });
};

export const useGetHofiySupportedCountries = () => {
  const { accessToken } = useAccessToken();

  const getCountriesSupported = useCallback(
    async () => getSupportedCountries(accessToken.accessToken),

    [accessToken.accessToken]
  );

  return useQuery({
    queryKey: ['supportedCountries'],
    queryFn: getCountriesSupported,
  });
};

export const useTalentDeliveryAddress = (talentId: string) => {
  const { accessToken } = useAccessToken();

  const getTalentDeliveryAddress = useCallback(
    async () => getDeliveryAddress(talentId, accessToken.accessToken),
    [accessToken.accessToken, talentId]
  );

  return useQuery({
    queryKey: ['TalentDeliveryAddress', `${talentId}`],
    queryFn: getTalentDeliveryAddress,
    enabled: !!talentId,
  });
};
