import React from 'react';

import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import { Popover } from '@mui/material';
import styled from 'styled-components';
import { palette, Button } from 'workmotion-design-system';

import { en } from 'localization';

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  align-items: center;
  border-top: 1px solid ${palette.greyscale.antiFlashWhite};
  padding: 1rem;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
  border-bottom: 1px solid ${palette.greyscale.antiFlashWhite};
  & > * {
    margin: 0.75rem;
  }
`;

const popoverStyle = {
  '& .MuiPaper-root': { borderRadius: '8px' },
};

const {
  filtersMenu: { applyBtn, resetBtn },
} = en;

interface FiltersMenuProps {
  onReset: () => void;
  onApply?: () => void;
  onClose?: () => void;
  dataCyReset?: string;
  dataCyApply?: string;
}

export const FiltersMenu: React.FC<FiltersMenuProps> = ({
  children,
  onReset,
  onApply,
  onClose,
  dataCyReset,
  dataCyApply,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const isOpen = Boolean(anchorEl);

  const onCloseEvent = () => {
    if (onClose) onClose();
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        onClick={handleClick}
        size="icon"
        btnType="subtle"
        data-cy="filter-btn"
        data-testid="filter-btn"
        style={{ padding: '1rem' }}
      >
        <FilterAltRoundedIcon style={{ color: palette.greyscale.sharkMouth }} />
      </Button>

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={onCloseEvent}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={popoverStyle}
        data-testid="filter-popover"
      >
        <InputContainer>{children}</InputContainer>

        <ButtonsContainer>
          <Button
            data-testid="filters-reset-btn"
            data-cy={dataCyReset}
            btnType="secondaryGrey"
            style={{
              width: 116,
            }}
            onClick={() => {
              onReset();
              setAnchorEl(null);
            }}
          >
            {resetBtn}
          </Button>

          <Button
            data-testid="filters-apply-btn"
            style={{ width: 116 }}
            onClick={() => {
              if (onApply) onApply();
              setAnchorEl(null);
            }}
            data-cy={dataCyApply}
          >
            {applyBtn}
          </Button>
        </ButtonsContainer>
      </Popover>
    </>
  );
};
