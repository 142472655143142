import React, { Suspense } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import * as Sentry from '@sentry/react';
import { BrowserRouter } from 'react-router-dom';
import { Spinner } from 'workmotion-design-system';

import { StyledToastContainer } from 'app/shared/styled-toast-container';

import { RoutesWithFeatureFlags } from './app';
import { AuthenticationContext } from './authentication/authentication-context';
import { NetworkContext } from './networking/network-context';
import { ErrorBoundary } from './tracking/error-boundary';

const WrappedRootComponent: React.FC = () => (
  <Suspense fallback={<Spinner />}>
    <NetworkContext>
      <BrowserRouter>
        <AuthenticationContext>
          <ErrorBoundary>
            <RoutesWithFeatureFlags />
          </ErrorBoundary>
        </AuthenticationContext>
      </BrowserRouter>
    </NetworkContext>

    <StyledToastContainer icon={false} />
  </Suspense>
);

export default Sentry.withProfiler(WrappedRootComponent);
