import React from 'react';

import type { ChipProps } from '@mui/material';
import { Chip } from '@mui/material';
import type { ChipColor } from 'workmotion-design-system';

import { chipColors } from './timeline-status';

const defaultStyle = {
  fontFamily: 'Work Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '0.75rem',
  lineHeight: '0.875rem',
  width: '8.75rem',
  height: '1.5rem',
};

interface StatusProps extends ChipProps {
  label: string;
  statusColor: ChipColor;
  chipStyle?: React.CSSProperties;
}

export const StatusBadge: React.FC<StatusProps> = ({
  label,
  statusColor,
  chipStyle,
  ...props
}) => (
  <Chip
    sx={{ ...defaultStyle, ...chipColors[statusColor], ...chipStyle }}
    label={label}
    {...props}
  />
);

export type { ChipColor };
