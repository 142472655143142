import React from 'react';

import KeyboardReturnRoundedIcon from '@mui/icons-material/KeyboardReturnRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';

import { en } from 'localization';

import { DropdownItemProps } from './dropdown';

const {
  tableRowActionItems: { preview, returnEquipment },
} = en;

export const previewItem: Omit<DropdownItemProps, 'onClick'> = {
  title: preview,
  dataCy: 'preview-action',
  icon: (
    <VisibilityRoundedIcon
      style={{
        fontSize: '1rem',
      }}
    />
  ),
};

export const returnItem: Omit<DropdownItemProps, 'onClick'> = {
  title: returnEquipment,
  dataCy: 'return-action',
  icon: (
    <KeyboardReturnRoundedIcon
      style={{
        fontSize: '1rem',
      }}
    />
  ),
};
