import type { FormikValues } from 'formik';
import { string as yupString, object as yupObject } from 'yup';

import { en } from 'localization';

const {
  managedView: {
    returnEquipment: {
      fields: { notes, reason, returnDate },
      validationMessages: { reasonRequired, returnDateRequired },
    },
  },
} = en;

export interface RequestReturnSchema extends FormikValues {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [reason.fieldName]: string;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [returnDate.fieldName]: string;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [notes.fieldName]: string;
}

export const returnInitialValues: RequestReturnSchema = {
  [reason.fieldName]: '',
  [returnDate.fieldName]: '',
  [notes.fieldName]: '',
};

export const returnValidation = yupObject().shape({
  [reason.fieldName]: yupString().required(reasonRequired),
  [returnDate.fieldName]: yupString().required(returnDateRequired),
});
