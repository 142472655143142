import type { SxProps } from '@mui/material';
import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

export const Title = styled.h3({
  fontSize: '1.5rem',
  color: `${palette.primary.genericViridian}`,
  fontWeight: '500',
  lineHeight: '1.75rem',
  textAlign: 'left',
  marginTop: '2.6rem',
  fontFamily: 'Work Sans',
});

export const Description = styled.p({
  fontSize: '0.875rem',
  color: `${palette.greyscale.UCLABlue}`,
  fontWeight: '300',
  lineHeight: '1.375rem',
  textAlign: 'left',
  marginTop: '0.75rem',
  marginBottom: '1.5rem',
  fontFamily: 'Work Sans',
});

export const Card = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '2rem 1.5rem 0rem 1.5rem',
  width: '43.75rem',
  background: `${palette.secondary.white}`,
  borderRadius: '0.5rem',
  marginBottom: '2rem',
});

export const Footer = styled.div({
  width: '43.75rem',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: '2rem',
});

export const CardStyles: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyItems: 'center',
  direction: 'center',
  alignSelf: 'center',
};

export const FiltersContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  zIndex: 1,
  width: '100%',
  padding: '2rem 1.5rem 1.5rem 1.5rem',
  borderBottom: `1px solid ${palette.greyscale.antiFlashWhite}`,
});

export const LayoutTitle = styled.p({
  display: 'block',
  fontSize: '2rem',
  fontWeight: 600,
  lineHeight: '2.5rem',
  color: palette.primary.midnightGreen,
});

export const LayoutDescription = styled.p({
  fontSize: '2rem',
  fontWeight: 600,
  lineHeight: '2.5rem',
  color: palette.primary.cerisePink,
});
