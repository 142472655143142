const enSomethingWentWrong =
  'Sorry for the inconvenience, we are experiencing some issues and working to get them fixed ASAP.';

export const en = {
  generic: {
    pageNotFoundScreen: {
      title: 'Page not found',
      subTitle1: 'The page you were looking for could not be found.',
      subTitle2:
        'It might have been removed, renamed, or did not exist in the first place.',
    },
    genricError: enSomethingWentWrong,
    demoCompanyError:
      'Sorry, you are not authorized to request equipment using a demo account.',
  },
  sharedComponents: {
    errorFallback: {
      somethingWentWrong: 'Hmm.. Something went wrong.',
      weHaveBeenNotified:
        'We have been notified with the problem and we will be investigating this as fast as we can',
      retry: 'Retry',
    },
  },
  managedView: {
    requestBtn: 'Request equipment',
    exportBtn: 'Export data',
    navigationTabs: {
      active: 'Active',
      return: 'Return',
    },
    emptyEquipmentList: {
      title: 'No equipment found',
      message: 'Here is where you will manage equipment request.',
    },
    activeTable: {
      columns: [
        'TALENT',
        'COUNTRY',
        'ORDER DATE',
        'EXPIRY DATE',
        'EQUIPMENT',
        'STATUS',
        '',
      ],
      statusLegend: {
        legendTitle: 'Status information',
        pending:
          'The order has been created, but not yet picked up by our partner.',
        in_progress: 'The order is being processed and packed by our partner.',
        shipped:
          'The item has been booked, a courier was booked and an estimated delivery date is scheduled.',
        delivered: 'The item has been delivered to the talent.',
        cancelled: 'The order was cancelled.',
      },
    },
    returnTable: {
      columns: [
        'TALENT',
        'COUNTRY',
        'RETURN DATE',
        'RETURN REASON',
        'EQUIPMENT',
        'STATUS',
        'ACTION',
      ],
      statusLegend: {
        legendTitle: 'Status information',
        pending:
          'The order has been created, but not yet picked up by our partner.',
        in_progress: 'The order is being processed and packed by our partner.',
        in_collection:
          'The collection pick-up has been booked with a courier, and an estimated collection date is filled out.',
        collected:
          'The item was picked up by a courier and is on its way to our partner’s warehouse.',
        completed: 'The item arrived back with our partner.',
        cancelled: 'The order was cancelled.',
      },
    },
    shipmentPreview: {
      title: 'Equipment details',
      inShipmentTitle: 'Shipment details',
      closeBtn: 'Close',
      details: {
        status: 'Status',
        id: 'ID',
        courier: 'Courier',
        trackingLink: 'Tracking link',
        monthlyPrice: 'Monthly price',
        invoiceAmount: 'Invoice amount',
        rentalPeriod: 'Rental period',
        collectionDate: 'Collection date',
        completedDate: 'Completed date',
        returnReason: 'Return reason',
        notes: 'Notes',
        months: 'months',
        deliveryDate: 'Delivery Date',
      },
      trackLink: 'Track',
    },
    returnEquipment: {
      title: 'Return equipment',
      cancelBtn: 'Cancel',
      submitBtn: 'Submit',
      fields: {
        reason: {
          fieldName: 'reason',
          label: 'Return reason',
          options: [
            { value: 'Maintenance/Exchange', label: 'Maintenance/Exchange' },
            { value: 'End of rental period', label: 'End of rental period' },
            { value: 'Early cancellation', label: 'Early cancellation' },
          ],
          placeholder: 'Select reason',
        },
        returnDate: { fieldName: 'Return date', label: 'Return date' },
        notes: {
          fieldName: 'notes',
          label: 'Notes',
          placeholder: 'Write your notes here',
        },
      },
      validationMessages: {
        reasonRequired: 'Return reason is reqiured',
        returnDateRequired: 'Return date is required',
      },
      successMessage: 'Your request was submitted successfully.',
    },
  },
  filtersMenu: {
    applyBtn: 'Apply',
    resetBtn: 'Reset',
    categoryPlaceHolder: 'Select category',
  },
  tableRowActionItems: {
    preview: 'Preview',
    returnEquipment: 'Return equipment',
  },
  filters: {
    statusPlaceholder: 'Select status',
  },
  pagination: {
    dropdownPrefix: 'Show',
    entityName: 'Timesheet',
    getPagesRangeText: (
      fromPage: number,
      toPage: number,
      total: number,
      entity: string | null
    ): string => `${fromPage} - ${toPage} of ${total} ${entity ? entity : ''}`,
  },
  filter: {
    employeePlaceHolder: 'search for talent',
    productFilterPlaceHolder: 'Search by product name',
  },
  requestEquipment: {
    layoutTitle: 'Request equipment & keep your team',
    layoutDescription: 'fully equipped!',
    wrapperTitle: 'Request equipment',
    firstStepDescription:
      'Please select the talent and the equipment you wish to provide your talent with.',
    placeOrderDescription:
      'Please confirm all the details before placing the order.',
    noResults: 'No equipment found',
    fields: {
      talent: {
        label: 'Talent Name*',
        placeholder: 'Search by talent name',
        fieldName: 'talent',
      },
      rentalPeriod: {
        label: 'Rental period',
        fieldName: 'rentalPeriod',
      },
      variant: {
        label: 'Variant*',
        fieldName: 'rentalPeriod',
      },

      productName: {
        label: '',
        fieldName: 'products',
        placeHolder: 'Search by product name',
      },
    },
    validationMessages: {
      talentValidationMessage: 'Talent name is Required',
      productNameValidationMessage: 'Product name is Required',
    },
    buttons: {
      primaryButton: 'Continue',
      secondryButton: 'Back',
    },
    paidMonthly: 'Per month',
    placeOrderStep: {
      deleteButton: 'Delete item',
      buttons: {
        primaryButton: 'PLACE ORDER',
        secondryButton: 'Back',
      },
      layoutTitle: 'Empower your team with the tools they',
      layoutDescription: 'need to thrive.',
      checkBoxLabel:
        'I thus affirm that I have read, understood, and fully accept the ',
      termsAndCondition: 'terms and conditions.',
      termsAndConditionUrl:
        'https://d3jmw2ahg7ct95.cloudfront.net/legal/HofyTermsOfService.pdf',
      termsAndConditionValidationMessage:
        'You must agree with the terms and conditions',
      equipmentTotalPrice: 'equipment total price',
      euipmentPriceTableHeaders: ['Equipment', 'Monthly', 'Total'],
      totalInvoice: 'TOTAL INVOICE AMOUNT',
    },
    deliveryAddress: {
      cardTitle: 'Delivery address',
      addressLine1: 'Address line 1',
      addressLine2: 'Address line 2',
      cityState: 'City / State',
      country: 'Country',
      postalCode: 'Postal code',
      editButton: 'EDIT',
      warningBanner:
        'Please make sure the delivery address of the talent is correct before proceeding. To be able to change it later you have to contact our support. ',
      confirmAddressInfoTip:
        'Please make sure the delivery address of the talent is correct. If you wish to change it please contact support',
      notSupportedAddress:
        'We currently don’t deliver to this country, please contact our support to provide you with other solutions.',
      formFields: {
        primaryBtn: 'save',
        secondryBtn: 'cancel',
        addressLine1: {
          fieldName: 'street',
          label: 'Address line 1*',
          placeholder: 'Write address here',
        },
        addressLine2: {
          fieldName: 'line2',
          label: 'Address line 2',
          placeholder: 'Apartment, unit, floor, etc.',
        },
        cityState: {
          fieldName: 'city',
          label: 'City / State*',
          placeholder: 'e.g. Rome.',
        },
        postalCode: {
          fieldName: 'postalCode',
          label: 'Postal code*',
          placeholder: 'e.g. 12345',
        },
        country: {
          fieldName: 'countryISOCode',
          label: 'Country*',
          placeholder: 'e.g. Italy',
        },
        validationMessages: {
          addressLine1Required: 'Address line 1 is reqiured',
          cityStateRequired: 'City / State is required',
          postalCodeRequired: 'Postal code is required',
          countryRequired: 'Country is required',
        },
      },
    },

    successMessage: 'Your request was submitted successfully.',
  },

  talentSearch: {
    placeholder: 'Search for a talent',
  },
};
