import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

export const AddressItemContainer = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${palette.greyscale.antiFlashWhite}`,
  paddingBottom: '1rem',
  marginBottom: '1rem',
  '&:last-child': {
    borderBottom: 'none',
  },
});

export const AddressItemDetails = styled.div({
  fontSize: '0.875rem',
  color: palette.greyscale.persianBlue,
});

export const AddressHeader = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '2.25rem',
});

export const AddressTitle = styled.p({
  fontWeight: 600,
  fontSize: '0.875rem',
  textTransform: 'uppercase',
  color: palette.primary.genericViridian,
});

export const AddressTitleContainer = styled.div({
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
  gap: '0.5rem',
});

export const Card = styled.div({
  width: '28.75rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  padding: '1.5rem 1.5rem 0rem 1.5rem',
  borderRadius: '0.5rem',
  marginBottom: '1.5rem',
  backgroundColor: palette.secondary.white,
});

export const AddressItem = styled.div({
  fontSize: '0.875rem',
  color: palette.greyscale.UCLABlue,
});

export const ModalButtonsFooterCustomStyle = {
  padding: '2rem 0 0',
  borderTop: `1px solid ${palette.greyscale.antiFlashWhite}`,
};

export const MainContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '2rem',
});
