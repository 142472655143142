import React, { useState } from 'react';

import { Pagination, Spinner } from 'workmotion-design-system';

import { useSelectedCompanyId } from 'app/manage-view/helpers/use-company-id';
import { useUrlParams } from 'app/manage-view/helpers/use-url-params';
import { CenterPositionStyle } from 'app/shared/styled-component';
import {
  AuthenticatedIdentity,
  useUserIdentity,
} from 'authentication/use-user-identity';
import { useActiveEquipmentRequests } from 'networking/equipment-api/equipment-requests';
import {
  EquipmentQueryParameters,
  EquipmentResponseDTO,
} from 'types/equipment-types';

import { ActiveTable } from './active-table';
import {
  DEFAULT_PAGE_SIZE_OPTIONS,
  usePagination,
} from '../../../helpers/use-pagination';
import { EmptyList } from '../empty-list';
import { FilterPanel } from '../filter-panel/filter-panel';
import { PreviewEquipment } from '../preview-equipment';
import { ReturnEquipment } from '../return-equipment';

export const ActiveView: React.FC = () => {
  const { companyId: userCompanyId } =
    useUserIdentity() as AuthenticatedIdentity;

  const { selectedCompanyId } = useSelectedCompanyId();

  const { equipmentIds } = useUrlParams();

  const {
    filters,
    currentPage,
    requestParams,
    currentPageSize,
    handleChangePage,
    handleChangePageSize,
    handleChangeFilters,
    removeFilters,
  } = usePagination();

  const { data: activeEquipmentList, isLoading } = useActiveEquipmentRequests({
    state: 'ACTIVE',
    companyId: selectedCompanyId || userCompanyId || null,
    direction: 'desc',
    ...(equipmentIds?.length > 0 && { equipmentIds: equipmentIds.join(',') }),
    ...(requestParams as EquipmentQueryParameters),
  });

  const [isPreviewShipmentOpen, setPreviewShipment] = useState<boolean>(false);
  const [isReturnModalOpen, setReturnModalOpen] = useState<boolean>(false);

  const [selectedEquipment, setSelectedEquipment] =
    useState<EquipmentResponseDTO>();

  if (isLoading) {
    return (
      <div style={CenterPositionStyle}>
        <Spinner data-testid="equipment-list-spinner" />
      </div>
    );
  }

  return (
    <div data-testid="Active-view-component" style={{ paddingBottom: '2rem' }}>
      <FilterPanel
        filters={filters}
        changeFilter={handleChangeFilters}
        removeFilter={removeFilters}
        isActive
      />

      {activeEquipmentList?.content.length > 0 ? (
        <>
          <ActiveTable
            equipmentList={activeEquipmentList.content}
            setSelectedEquipment={setSelectedEquipment}
            openPreviewModal={setPreviewShipment}
            openReturnModal={setReturnModalOpen}
          />

          <Pagination
            total={activeEquipmentList?.page.totalElements}
            currentPage={currentPage}
            onChange={handleChangePage}
            pageSize={currentPageSize}
            onPageSizeChanged={handleChangePageSize}
            pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
          />
        </>
      ) : (
        <EmptyList />
      )}

      <PreviewEquipment
        isOpen={isPreviewShipmentOpen}
        closeModal={() => {
          setPreviewShipment(false);
        }}
        selectedEquipment={selectedEquipment}
      />

      {selectedEquipment && (
        <ReturnEquipment
          isOpen={isReturnModalOpen}
          closeModal={() => {
            setReturnModalOpen(false);
          }}
          selectedItem={selectedEquipment}
        />
      )}
    </div>
  );
};
