import React from 'react';

import type { ChipColor } from 'workmotion-design-system';

import { InfoSideMenu } from 'app/shared/info-drawer/info-side-menu';
import { StyledInfoBox } from 'app/shared/info-drawer/sidemenu-styled-components';
import { ActiveStateStatus, ReturnStateStatus } from 'types/equipment-types';

import { StatusBadge } from './status-badge';

export const StatusHeader: React.FC<{
  columnName: string;
  statusLegend: { legendTitle: string; [x: string]: string };
  availableStatuses: Array<ActiveStateStatus | ReturnStateStatus>;
  statusColors: { [x: string]: ChipColor };
}> = ({ columnName, statusLegend, availableStatuses, statusColors }) => (
  <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
    {columnName}

    <InfoSideMenu sideMenuTitle={statusLegend.legendTitle}>
      {availableStatuses.map(status => (
        <StyledInfoBox key={status}>
          <StatusBadge
            label={status.replace('_', ' ')}
            statusColor={statusColors[status]}
            data-testid={`${status}-info-badge`}
            data-cy={`${status}-info-badge`}
            chipStyle={{ width: '190px' }}
          />

          {statusLegend[status.toLocaleLowerCase()]}
        </StyledInfoBox>
      ))}
    </InfoSideMenu>
  </div>
);
