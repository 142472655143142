import * as yup from 'yup';

import { en } from 'localization';

const {
  requestEquipment: {
    fields: { talent },
    validationMessages: { talentValidationMessage },
  },
} = en;

export const addressInitialValues = () => ({
  [talent.fieldName]: '',
});

export const addressSchema = yup.object().shape({
  [talent.fieldName]: yup.string().required(talentValidationMessage),
});
