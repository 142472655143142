import React, { FC, useEffect, useState } from 'react';

import { palette, Table } from 'workmotion-design-system';

import { en } from 'localization';

import {
  CostContainer,
  Title,
  PriceRow,
  ProductNameRow,
  TotalPriceContainer,
} from './styled.component';
import { ProductPostObject } from '..';

export const CostCard: FC<{ products: ProductPostObject[] }> = ({
  products,
}) => {
  const {
    requestEquipment: {
      placeOrderStep: {
        equipmentTotalPrice,
        euipmentPriceTableHeaders,
        totalInvoice,
      },
    },
  } = en;

  const [totalInvoiceAmount, setTotalInvoiceAmount] = useState<number>();

  useEffect(() => {
    const productsCost = products.map(
      product => +product.costMonthly * product.orderItems.rentalPeriodMonths
    );

    const totalCost = productsCost.reduce((partialSum, a) => partialSum + a, 0);

    setTotalInvoiceAmount(+totalCost.toFixed(2));
  }, [products]);

  const tableColumns = euipmentPriceTableHeaders.map(header => ({
    cell: header,
  }));

  const tableRows = products.map(
    ({ costMonthly, productName, orderItems }) => ({
      cells: [
        <ProductNameRow
          style={{
            width: '8.75rem',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
          key={orderItems.variantId}
        >
          {productName}
        </ProductNameRow>,
        <PriceRow key={orderItems.variantId}>{`${costMonthly} EUR`}</PriceRow>,
        <PriceRow key={orderItems.variantId}>{`${(
          +costMonthly * orderItems.rentalPeriodMonths
        ).toFixed(2)} EUR`}</PriceRow>,
      ],
    })
  );

  return (
    <CostContainer>
      <Title>{equipmentTotalPrice}</Title>

      <Table
        columns={tableColumns}
        rows={tableRows}
        backgroundColor={palette.secondary.white}
      ></Table>

      <TotalPriceContainer>
        <p>{totalInvoice}</p>

        <p>{totalInvoiceAmount} EUR</p>
      </TotalPriceContainer>
    </CostContainer>
  );
};
