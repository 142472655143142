import React from 'react';

import { formatUTCDate } from 'app/manage-view/helpers/format-date-utils';
import { en } from 'localization';
import {
  EquipmentResponseDTO,
  ActiveStateStatusEnum,
} from 'types/equipment-types';

import { PreviewItem } from './preview-item';
import { TrackingLink } from './tracking-link';
import { StatusBadge } from '../../cells/status-badge';
import { ActiveStatusColors } from '../../cells/status-colors';

const {
  managedView: {
    shipmentPreview: { details },
  },
} = en;

export const ActivePreviewDetails: React.FC<{
  selectedEquipment: EquipmentResponseDTO;
}> = ({ selectedEquipment }) => (
  <>
    <PreviewItem
      label={details.status}
      value={
        <StatusBadge
          label={selectedEquipment.activeStateStatus.replace('_', ' ')}
          statusColor={ActiveStatusColors[selectedEquipment.activeStateStatus]}
          data-testid={`${selectedEquipment.activeStateStatus}-info-badge`}
          data-cy={`${selectedEquipment.activeStateStatus}-info-badge`}
        />
      }
    />

    {selectedEquipment.activeStateStatus === ActiveStateStatusEnum.DELIVERED ? (
      <>
        <PreviewItem
          label={details.deliveryDate}
          value={`${formatUTCDate(selectedEquipment.deliveredAt)}`}
          attrs={{
            item: { 'data-testid': 'delivery-date' },
            value: { 'data-cy': 'delivery-date-value' },
            label: { 'data-cy': 'delivery-date-label' },
          }}
        />

        <PreviewItem
          label={details.monthlyPrice}
          value={`${selectedEquipment.pricePerMonth} ${selectedEquipment.currency}`}
          attrs={{
            item: { 'data-testid': 'monthly-price-item' },
            value: { 'data-cy': 'monthly-price-value' },
            label: { 'data-cy': 'monthly-price-label' },
          }}
        />

        <PreviewItem
          label={details.invoiceAmount}
          value={
            selectedEquipment.totalPrice && selectedEquipment.currency
              ? `${selectedEquipment.totalPrice} ${selectedEquipment.currency}`
              : null
          }
          attrs={{
            item: { 'data-testid': 'invoice-amount-item' },
            value: { 'data-testid': 'invoice-amount-value' },
            label: { 'data-cy': 'invoice-amount-label' },
          }}
        />

        <PreviewItem
          label={details.rentalPeriod}
          value={`${String(selectedEquipment.rentalPeriodMonths)} ${
            details.months
          }`}
          attrs={{
            item: { 'data-testid': 'rental-period-item' },
            value: { 'data-cy': 'rental-period-value' },
            label: { 'data-cy': 'rental-period-label' },
          }}
        />
      </>
    ) : (
      <>
        <PreviewItem
          label={details.id}
          value={selectedEquipment.shipmentDetails?.trackingReference}
          attrs={{
            item: { 'data-testid': 'tracking-id-item' },
            value: { 'data-cy': 'tracking-id-value' },
            label: { 'data-cy': 'tracking-id-label' },
          }}
        />

        <PreviewItem
          label={details.courier}
          value={selectedEquipment.shipmentDetails?.courier}
          attrs={{
            item: { 'data-testid': 'courier-item' },
            value: { 'data-cy': 'courier-value' },
            label: { 'data-cy': 'courier-label' },
          }}
        />

        <PreviewItem
          label={details.trackingLink}
          value={
            <TrackingLink
              link={selectedEquipment.shipmentDetails?.trackingLink}
            />
          }
          attrs={{
            item: { 'data-testid': 'tracking-link-item' },
            value: { 'data-cy': 'tracking-link-value' },
            label: { 'data-cy': 'tracking-link-label' },
          }}
        />
      </>
    )}
  </>
);
