import { useCallback } from 'react';

import { useQuery } from '@tanstack/react-query';

import { QueryParameters } from 'types';

import { fetchAllEmployees, getTalentDetails } from './employee-client';
import { useAccessToken } from '../../authentication/use-access-token';

export const useEmployeeRequests = (params: QueryParameters) => {
  const { accessToken } = useAccessToken();

  const queryFn = async () =>
    fetchAllEmployees(accessToken?.accessToken, params);

  return useQuery({
    queryKey: ['employees', params],
    queryFn,
    enabled: !!params,
    keepPreviousData: true,
  });
};

export const useTalentDetails = (talentId: string) => {
  const { accessToken } = useAccessToken();

  const queryFn = useCallback(
    async () => getTalentDetails(talentId, accessToken.accessToken),
    [talentId, accessToken.accessToken]
  );

  return useQuery({
    queryKey: ['contract-details', talentId],
    queryFn,
    enabled: !!talentId,
  });
};
