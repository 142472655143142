import type { ChipColor } from 'workmotion-design-system';

import {
  ActiveStateStatusEnum,
  ReturnStateStatusEnum,
} from 'types/equipment-types';

export const ActiveStatusColors: { [key in ActiveStateStatusEnum]: ChipColor } =
  {
    [ActiveStateStatusEnum.PENDING]: 'gray',
    [ActiveStateStatusEnum.IN_PROGRESS]: 'curry',
    [ActiveStateStatusEnum.SHIPPED]: 'blue',
    [ActiveStateStatusEnum.DELIVERED]: 'green',
    [ActiveStateStatusEnum.CANCELLED]: 'red',
  };

export const ReturnStatusColors: { [key in ReturnStateStatusEnum]: ChipColor } =
  {
    [ReturnStateStatusEnum.PENDING]: 'gray',
    [ReturnStateStatusEnum.IN_PROGRESS]: 'curry',
    [ReturnStateStatusEnum.IN_COLLECTION]: 'blue',
    [ReturnStateStatusEnum.COLLECTED]: 'green',
    [ReturnStateStatusEnum.COMPLETED]: 'fullGreen',
    [ReturnStateStatusEnum.CANCELLED]: 'red',
  };
