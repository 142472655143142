import React from 'react';

import { useField } from 'formik';
import { Select } from 'workmotion-design-system';

import { en } from 'localization';

const {
  managedView: {
    returnEquipment: {
      fields: {
        reason: { fieldName, label, options, placeholder },
      },
    },
  },
} = en;

export const Reason: React.FC = () => {
  const [field, meta, { setValue }] = useField(fieldName);

  const showError = !!(meta.touched && meta.error);

  return (
    <Select
      {...field}
      label={`${label}*`}
      options={options}
      showError={showError}
      errorMessage={meta.error}
      placeholder={placeholder}
      onChange={setValue}
      attrs={{
        formField: {
          attrs: {
            label: {
              'data-cy': 'return-reason-label',
            },
            error: {
              'data-cy': 'return-reason-errormsg',
            },
          },
        },
        select: {
          'data-cy': 'return-reason-select',
          'data-testid': 'return-reason-select',
        },
        input: {
          'data-cy': 'return-reason-input',
        },
      }}
    />
  );
};
