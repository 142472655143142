import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

export const ItemContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignContent: 'center',
  alignItems: 'center',
  padding: '1.68rem 1rem',
  gap: '0.5rem',
  width: '100%',
  cursor: 'pointer',
  '&:not(:last-child)': {
    borderBottom: `2px solid ${palette.greyscale.antiFlashWhite}`,
  },
});

export const ProductImage = styled.img({
  width: '2.25rem',
});

export const ProductName = styled.p({
  color: palette.greyscale.persianBlue,
  fontSize: '0.875rem',
  fontWeight: '400',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
});

export const ProductPrice = styled.p({
  color: palette.greyscale.persianBlue,
  fontSize: '0.93rem',
  fontWeight: '600',
  lineHeight: '1.125rem',
});

export const Period = styled.p({
  color: palette.greyscale.UCLABlue,
  fontSize: '0.875rem',
  fontWeight: '400',
  lineHeight: '0.875rem',
});

export const ProductPriceContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  gap: '0.5rem',
  flexShrink: 0,
});
