import { Document, OpenAPIClientAxios } from 'openapi-client-axios';

import { Client } from 'networking/equipment-api/equipment-client-types';
import {
  GetEquipmentQueryParameters,
  EquipmentReturnRequest,
  OrderEquipmentDeliveryAddressDTO,
} from 'types/equipment-types';

import ClientDefinition from './equipment-contract.json';

export async function createEquipmentClient(
  accessToken: string
): Promise<Client> {
  if (accessToken == null) {
    console.error('Access token is null!');

    return;
  }

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const api = new OpenAPIClientAxios({
    definition: ClientDefinition as Document,
    axiosConfigDefaults: {
      headers,
      baseURL: window.env.API_BASE_URL,
      transitional: {},
    },
  });

  await api.init();

  return api.getClient<Client>();
}

export async function getActiveEquipmentList(
  params: GetEquipmentQueryParameters,
  token: string
) {
  const client = await createEquipmentClient(token);
  const response = await client.getEquipment(params);

  return response.data;
}

export async function getTalentEquipmentList(
  talentId: string,
  countryCode: string,
  token: string
) {
  const client = await createEquipmentClient(token);
  const response = await client.getEquipmentProducts({ talentId, countryCode });

  return response.data;
}

export async function orderEquipmentsForTalent(
  talentId: string,
  orderItems: any,
  deliveryAddress: OrderEquipmentDeliveryAddressDTO,
  token: string
) {
  const client = await createEquipmentClient(token);

  const response = await client.orderEquipment(
    {},
    {
      talentId,
      orderItems,
      deliveryAddress,
    }
  );

  return response.data;
}

export async function requestReturnEquipment(
  equipmentId: string,
  requestBody: EquipmentReturnRequest,
  token: string
) {
  const client = await createEquipmentClient(token);

  const response = await client.returnEquipment(
    { equipmentId: equipmentId },
    {
      ...requestBody,
    }
  );

  return response.data;
}

function downloadFileFromResponse(data, filename, mime?, bom?) {
  let blobData = typeof bom !== 'undefined' ? [bom, data] : [data];
  let blob = new Blob(blobData, { type: mime || 'application/octet-stream' });

  if (typeof (window.navigator as any).msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    (window.navigator as any).msSaveBlob(blob, filename);
  } else {
    let blobURL =
      window.URL && window.URL.createObjectURL
        ? window.URL.createObjectURL(blob)
        : window.webkitURL.createObjectURL(blob);
    let tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
    }

    document.body.appendChild(tempLink);
    tempLink.click();

    // Fixes "webkit blob resource error 1"
    setTimeout(function () {
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(blobURL);
    }, 200);
  }
}

export async function getEquipmentReport(
  params: GetEquipmentQueryParameters,
  token: string
) {
  const client = await createEquipmentClient(token);

  const response = await client.exportEquipmentToCsv(params, undefined, {
    responseType: 'blob',
  });

  downloadFileFromResponse(response.data, 'equipments.csv');

  return response.data;
}

export async function getSupportedCountries(token: string) {
  const client = await createEquipmentClient(token);
  const response = await client.getEquipmentConfigurations();

  return response.data.supportedCountries;
}

export async function getDeliveryAddress(talentId: string, token: string) {
  const client = await createEquipmentClient(token);
  const response = await client.getDeliveryAddress({ talentId });

  return response.data;
}
