import React from 'react';

import { Form, Formik } from 'formik';
import { Modal, ModalButtonsFooter } from 'workmotion-design-system';

import { ModalProps } from 'app/manage-view/equipment-list/equipment-view/return-equipment';
import { en } from 'localization';
import { AddressDTO } from 'types/employee';

import { AddressLine1Field } from './form-fields/address-line1-field';
import { AddressLine2Field } from './form-fields/address-line2-field';
import { CityStateField } from './form-fields/city-state-field';
import { CountryField } from './form-fields/country-field';
import { PostalCodeField } from './form-fields/postal-code-field';
import { AddressInitialValues, addressValidation } from './formik-values-setup';
import {
  MainContainer,
  ModalButtonsFooterCustomStyle,
} from '../styled-component';

const {
  requestEquipment: {
    deliveryAddress: {
      cardTitle,
      formFields: { primaryBtn, secondryBtn },
    },
  },
} = en;

interface DeleviryModalProps extends ModalProps<AddressDTO> {
  setAddressValues: (address: AddressDTO) => void;
}

export const AddressModalForm: React.FC<DeleviryModalProps> = ({
  closeModal,
  isOpen,
  selectedItem,
  setAddressValues,
}) => (
  <Modal
    isOpen={isOpen}
    closeModal={closeModal}
    header={cardTitle}
    data-testid="EditModel"
  >
    <Formik
      initialValues={AddressInitialValues(selectedItem)}
      onSubmit={values => setAddressValues(values)}
      validationSchema={addressValidation}
    >
      <Form
        style={{
          width: '100%',
          fontWeight: 400,
        }}
      >
        <MainContainer>
          <AddressLine1Field />

          <AddressLine2Field />

          <CountryField countryIsoCode={selectedItem?.countryISOCode} />

          <CityStateField />

          <PostalCodeField />
        </MainContainer>

        <ModalButtonsFooter
          placement="space-between"
          buttonsInfo={[
            {
              onClick: closeModal,
              text: secondryBtn,
              btnType: 'secondaryGrey',
              dataCy: 'cancel-button',
            },
            {
              text: primaryBtn,
              dataCy: 'save-button',
              type: 'submit',
            },
          ]}
          customStyle={ModalButtonsFooterCustomStyle}
        />
      </Form>
    </Formik>
  </Modal>
);
