import styled from 'styled-components';

export const Cell = styled.div({
  width: 'fit-content',
  height: '100%',
});

export const EquipmentNameCell = styled.div({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  maxWidth: '160px',
});
