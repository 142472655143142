import React from 'react';

import * as Sentry from '@sentry/react';
import styled from 'styled-components';
import { Button } from 'workmotion-design-system';

import ErrorImage from './error-image.svg';
import { en } from '../localization';

const StyledWrapper = styled.section({
  border: '1px solid #D9DCE3',
  borderRadius: 3,
  margin: '12px 26px 0 16px',
  height: 'calc(100vh - 166px)',
  textAlign: 'center',
  paddingTop: 200,
});

const StyledTitle = styled.h2({
  color: '#00234B',
  fontSize: 24,
  fontWeight: 'bold',
  lineHeight: '38px',
  margin: '30px 0 8px',
  fontFamily: 'Archivo',
});

const StyledMessage = styled.p({
  color: '#5A6487',
  fontSize: 14,
  lineHeight: '21px',
  fontWeight: 300,
  maxWidth: 360,
  margin: '0 auto',
  marginBottom: 32,
});

interface ErrorFallbackBodyProps {
  title: string;
  message: string;
  handleRetry?: () => void;
}

const refreshPage = () => {
  const url = window.location.href;
  window.location.href = url;
};

const ErrorFallbackBody: React.FC<ErrorFallbackBodyProps> = ({
  title,
  message,
  handleRetry = refreshPage,
}) => {
  const {
    sharedComponents: {
      errorFallback: { retry },
    },
  } = en;

  return (
    <>
      <ErrorImage />

      <StyledTitle>{title}</StyledTitle>

      <StyledMessage>{message}</StyledMessage>

      <Button type="button" onClick={handleRetry} style={{ width: 160 }}>
        {retry}
      </Button>
    </>
  );
};

const ErrorFallback: React.FC = () => {
  const {
    sharedComponents: {
      errorFallback: { somethingWentWrong, weHaveBeenNotified },
    },
  } = en;

  return (
    <StyledWrapper role="alert">
      <ErrorFallbackBody
        title={somethingWentWrong}
        message={weHaveBeenNotified}
      />
    </StyledWrapper>
  );
};

export const ErrorBoundary = ({ children }) => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
    {children}
  </Sentry.ErrorBoundary>
);
