import type { SxProps } from '@mui/material';
import { Paper } from '@mui/material';
import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

export const autocompleteOptionStyles = {
  color: palette.greyscale.UCLABlue,
  fontFamily: 'Work Sans, sans serif',
  fontWeight: 400,
  fontSize: '0.75rem',
  height: '40px',
  '&.MuiAutocomplete-option:hover': {
    backgroundColor: `${palette.greyscale.antiFlashWhite}`,
  },
  '&.MuiAutocomplete-option[aria-selected="true"]': {
    backgroundColor: `${palette.tertiary.aquaHaze} !important`,
  },
};

export const StyledPaper = styled(Paper)({
  borderRadius: '8px',
  top: '2px',
});

export const autoCompleteStyles: SxProps = {
  width: '100%',
  '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
    paddingLeft: '1rem',
    height: '40px',
  },
  '& .MuiAutocomplete-root': {
    borderRadius: '8px',
  },

  '& .MuiAutocomplete-input': {
    fontFamily: 'Work Sans',
    color: palette.greyscale.persianBlue,
    fontSize: '0.75rem',
    fontWeight: 400,
    '&.Mui-disabled': {
      color: palette.greyscale.sharkMouth,
    },
  },

  '& .MuiAutocomplete-noOptions': {
    fontSize: '0.75rem',
    fontWeight: 400,
    fontFamily: 'Work Sans, sans serif',
  },
  '& .MuiAutocomplete-popupIndicator': {
    color: palette.greyscale.sharkMouth,
  },
};
