import React, { LabelHTMLAttributes, PropsWithChildren } from 'react';

import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

const StyledLabel = styled.label({
  display: 'flex',
  flexDirection: 'column',
});

const StyledMainText = styled.span({
  color: `${palette.greyscale.persianBlue}`,
  fontSize: '.875rem',
  textTransform: 'capitalize',
  fontWeight: 500,
  display: 'flex',
  alignItems: 'center',
});

const StyledOptionalText = styled.span({
  color: `${palette.tertiary.aquaHaze}`,
  fontSize: 14,
  marginTop: 7,
});

const StyledFieldError = styled.span({
  fontSize: '.75rem',
  color: `${palette.semantic.red}`,
  marginTop: '.5rem',
  marginBottom: '-1.25rem',
  alignSelf: 'flex-start',
});

export interface LabelProps
  extends PropsWithChildren<LabelHTMLAttributes<HTMLLabelElement>> {
  name?: string;
  optional?: boolean;
  infoIcon?: React.ReactNode;
}

const defaultProps: LabelProps = {
  name: '',
  optional: false,
  infoIcon: undefined,
};

const FieldLabel: React.FC<LabelProps> = ({
  name,
  children,
  infoIcon,
  optional = false,
  ...props
}) => (
  <StyledLabel htmlFor={name} {...props}>
    <StyledMainText>
      {children}

      {infoIcon}
    </StyledMainText>

    {optional && <StyledOptionalText>Optional</StyledOptionalText>}
  </StyledLabel>
);

const FieldErrorMessage: React.FC<
  PropsWithChildren<Record<string, unknown>>
> = ({ children, ...rest }) => (
  <StyledFieldError {...rest}>{children}</StyledFieldError>
);

FieldLabel.defaultProps = defaultProps;

export { FieldLabel, FieldErrorMessage };
