import React from 'react';

import { Navigate, Route, useLocation, useNavigate } from 'react-router-dom';
import { Button, TabNavigatorV2 } from 'workmotion-design-system';

import {
  AuthenticatedIdentity,
  useUserIdentity,
} from 'authentication/use-user-identity';
import { en } from 'localization';
import { SentryRoutes } from 'tracking/routes';

import { ActiveView } from './equipment-list/equipment-view/active-view';
import { ReturnView } from './equipment-list/equipment-view/return-view';

const {
  managedView: { requestBtn, navigationTabs },
} = en;

const tabList = [
  {
    value: '/equipment/active',
    label: navigationTabs.active,
    'data-cy': 'equipment-active-btn',
  },
  {
    value: '/equipment/return',
    label: navigationTabs.return,
    'data-cy': 'equipment-return-btn',
  },
];

export const ManagedView: React.FC = () => {
  const navigate = useNavigate();

  const { isHrManager, isCompanyAdmin } =
    useUserIdentity() as AuthenticatedIdentity;

  const { pathname } = useLocation();

  return (
    <>
      {(isHrManager || isCompanyAdmin) && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            btnType="lightGreen"
            data-testid="request-equipment-btn"
            data-cy="request-equipment-btn"
            onClick={() => {
              navigate('/equipment/request-equipment');
            }}
          >
            {requestBtn}
          </Button>
        </div>
      )}

      <TabNavigatorV2 tabList={tabList} onChange={navigate} value={pathname} />

      <SentryRoutes>
        <Route path="/" element={<Navigate to={'active'} />} />

        <Route path="/active" element={<ActiveView />} />

        <Route path="/return" element={<ReturnView />} />
      </SentryRoutes>
    </>
  );
};
