import * as EquipmentClientTypes from 'networking/equipment-api/equipment-client-types';

export type EquipmentResponseDTO =
  EquipmentClientTypes.Components.Schemas.EquipmentResponseDTO;

export type ActiveStateStatus =
  EquipmentClientTypes.Components.Schemas.ActiveStateStatus;

export type EquipmentState =
  EquipmentClientTypes.Components.Schemas.EquipmentState;

export enum ActiveStateStatusEnum {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  SHIPPED = 'SHIPPED',
  DELIVERED = 'DELIVERED',
  CANCELLED = 'CANCELLED',
}

export type ReturnStateStatus =
  EquipmentClientTypes.Components.Schemas.ReturnStateStatus;

export enum ReturnStateStatusEnum {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  IN_COLLECTION = 'IN_COLLECTION',
  COLLECTED = 'COLLECTED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
}

export type EquipmentEmployeeDTO =
  EquipmentClientTypes.Components.Schemas.EquipmentEmployeeDTO;

export type EquipmentResponsePageDTO =
  EquipmentClientTypes.Components.Schemas.EquipmentResponsePageDTO;

export type GetEquipmentQueryParameters =
  // EquipmentClientTypes.Paths.GetEquipment.QueryParameters;
  {
    talentId?: string;
    companyId?: string;
    state?: EquipmentState;
    activeStatus?: ActiveStateStatus;
    returnStatus?: ReturnStateStatus;
    equipmentIds?: string;
  };

export type DefaultQueryParameters =
  EquipmentClientTypes.Components.QueryParameters;

export interface EquipmentQueryParameters extends GetEquipmentQueryParameters {
  page: number;
  size: number;
  direction?: string;
}

export type EquipmentProductVariantDTO =
  EquipmentClientTypes.Components.Schemas.EquipmentProductVariantDTO;

export type EquipmentProductDTOoriginal =
  EquipmentClientTypes.Components.Schemas.EquipmentProductDTO;

export interface EquipmentProductDTO extends EquipmentProductDTOoriginal {
  id: string;
}

export type EquipmentProductVariantPricingDTO =
  EquipmentClientTypes.Components.Schemas.EquipmentProductVariantPricingDTO;

export type OrderEquipmentRequest =
  EquipmentClientTypes.Components.Schemas.OrderEquipmentRequest;

export type EquipmentOrderItemDTO =
  EquipmentClientTypes.Components.Schemas.EquipmentOrderItemDTO;

export type EquipmentReturnRequest =
  EquipmentClientTypes.Components.Schemas.EquipmentReturnRequest;

export type OrderEquipmentDeliveryAddressDTO =
  EquipmentClientTypes.Components.Schemas.OrderEquipmentDeliveryAddressDTO;
