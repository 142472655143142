import type { FormikValues } from 'formik';
import { string as yupString, object as yupObject } from 'yup';

import { en } from 'localization';
import { AddressDTO } from 'types/employee';

const {
  requestEquipment: {
    deliveryAddress: {
      formFields: {
        addressLine1,
        addressLine2,
        cityState,
        postalCode,
        country,
        validationMessages: {
          addressLine1Required,
          cityStateRequired,
          postalCodeRequired,
          countryRequired,
        },
      },
    },
  },
} = en;

export interface AddressSchema extends FormikValues {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [addressLine1.fieldName]: string;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [addressLine2.fieldName]: string;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [cityState.fieldName]: string;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [postalCodeRequired.fieldName]: nubmer;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [country.fieldName]: nubmer;
}

export const AddressInitialValues = (address: AddressDTO): AddressSchema => ({
  [addressLine1.fieldName]: address?.street,
  [addressLine2.fieldName]: address?.line2,
  [postalCode.fieldName]: address?.postalCode,
  [cityState.fieldName]: address?.city,
  [country.fieldName]: address?.countryISOCode,
});

export const addressValidation = yupObject().shape({
  [addressLine1.fieldName]: yupString().required(addressLine1Required),
  [cityState.fieldName]: yupString().required(cityStateRequired),
  [postalCode.fieldName]: yupString().required(postalCodeRequired),
  [country.fieldName]: yupString().required(countryRequired),
});
