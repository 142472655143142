import { createContext, useCallback, useState } from 'react';

import { EquipmentProductDTO } from '../../../types/equipment-types';

type SelectedProducts = {
  products: EquipmentProductDTO[];
  selectProduct: (product: EquipmentProductDTO) => void;
  deselectProduct: (product: EquipmentProductDTO) => void;
};

export function useSelectedProducts(): SelectedProducts {
  const [selectedProducts, setSelectedProducts] = useState<
    EquipmentProductDTO[]
  >([]);

  const toggleProduct = useCallback(
    (product: EquipmentProductDTO) => {
      setSelectedProducts(prevState => {
        if (prevState.find(p => p.id === product.id)) {
          return [...prevState.filter(p => p.id !== product.id)];
        } else {
          return [...prevState, product];
        }
      });
    },
    [setSelectedProducts]
  );

  return {
    products: selectedProducts,
    selectProduct: toggleProduct,
    deselectProduct: toggleProduct,
  };
}

export const SelectedProductsContext = createContext<SelectedProducts>({
  products: [],
  selectProduct: () => {},
  deselectProduct: () => {},
});
