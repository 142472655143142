import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { useField } from 'formik';
import type { SelectOption } from 'workmotion-design-system';
import { InputField, palette } from 'workmotion-design-system';

import { StyledFieldContainer } from 'app/shared/styled-component';
import { en } from 'localization';
import { EquipmentProductDTO } from 'types/equipment-types';

import { FiltersContainer } from './request-equipment-styled';
import { SelectableEquipmentCard } from './selectable-equipment-card';
import { SelectedProductsContext } from './selected-products-context';
import { EquipmentFilterMenu } from '../equipment-filter-menu';

export const FormikProductList: React.FC<{
  options: EquipmentProductDTO[];
}> = ({ options }) => {
  const {
    requestEquipment: {
      noResults,
      fields: {
        productName: { fieldName, label, placeHolder },
      },
    },
  } = en;

  const [, meta, { setValue }] = useField(fieldName);

  const showError = !!(meta.touched && meta.error);

  const [searchTerm, setSearchTerm] = useState('');

  const [filteredOptions, setFilteredOptions] = useState(options);

  const [categoriesOptions, setCategoriesOptions] = useState<SelectOption[]>();
  const [category, setCategory] = useState<string>();

  const resetFilters = useCallback(() => {
    setFilteredOptions(options);
    setCategory(null);
  }, [options]);

  const extractCategories = useCallback(() => {
    const categoriesUniqueSet = new Set(options?.map(item => item.category));

    const categoryUniqueArray = [...categoriesUniqueSet];

    const categoriesList: SelectOption[] = categoryUniqueArray.map(
      (item): SelectOption => ({
        label: item?.replaceAll('_', ' ').toUpperCase(),
        value: item,
      })
    );

    setCategoriesOptions(categoriesList);
  }, [options]);

  useEffect(() => {
    setFilteredOptions(options);
    extractCategories();
  }, [options, extractCategories]);

  const applyFilter = useCallback(
    (selectedCategory: string): void => {
      if (selectedCategory) {
        const filterProducts = options.filter(
          item => item.category === selectedCategory
        );

        setCategory(selectedCategory);

        setFilteredOptions(filterProducts);
      }
    },
    [options]
  );

  useEffect(() => {
    if (searchTerm === '') {
      setFilteredOptions(options);
    } else {
      setFilteredOptions(
        options.filter(option =>
          option.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [options, searchTerm, setFilteredOptions]);

  const { products } = useContext(SelectedProductsContext);

  useEffect(() => {
    setValue(products);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  return (
    <StyledFieldContainer>
      <FiltersContainer>
        <Box width="65%">
          <InputField
            attrs={{ input: { 'data-cy': 'product-search' } }}
            id="product-search"
            inputSize="fullWidth"
            label={label}
            value={searchTerm}
            placeholder={placeHolder}
            onChange={e => setSearchTerm(e.target.value)}
          />
        </Box>

        <EquipmentFilterMenu
          options={categoriesOptions}
          onApplyFilter={applyFilter}
          onResetFilter={resetFilters}
          category={category}
        />
      </FiltersContainer>

      <Box
        width="100%"
        padding={'0 1rem'}
        sx={{
          height: '50vh',
          overflow: 'scroll',
          '@media screen and ( max-height: 1080px ) ': { height: '40vh' },
          '@media screen and ( max-height: 720px ) ': { height: '30vh' },
        }}
      >
        {!filteredOptions.length && (
          <p
            style={{
              marginTop: '2rem',
              fontSize: '0.875rem',
              fontWeight: '400',
              padding: '0.5rem',
              color: palette.greyscale.persianBlue,
            }}
            data-testid="no-equipment-found"
          >
            {noResults}
          </p>
        )}

        <SelectedProductsContext.Consumer>
          {value =>
            filteredOptions?.map(option => (
              <SelectableEquipmentCard
                option={option}
                selectedOptions={value.products}
                selectProduct={value.selectProduct}
                deselectProduct={value.deselectProduct}
                highlightErrorInCheckbox={showError}
                key={option.id}
              />
            ))
          }
        </SelectedProductsContext.Consumer>
      </Box>
    </StyledFieldContainer>
  );
};
