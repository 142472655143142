import React, { FC, useCallback, useEffect, useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import PinDropIcon from '@mui/icons-material/PinDrop';
import { Box } from '@mui/material';
import { Button, InfoTip, Spinner, palette } from 'workmotion-design-system';

import { en } from 'localization';
import {
  useGetHofiySupportedCountries,
  useTalentDeliveryAddress,
} from 'networking/equipment-api/equipment-requests';
import { AddressDTO, EmployeeResponseDTO } from 'types/employee';

import { AddressModalForm } from './address-modal-form';
import { getCountryNameByIsoCode } from './get-country-name';
import {
  AddressHeader,
  AddressItemContainer,
  AddressTitle,
  Card,
  AddressTitleContainer,
  AddressItem,
  AddressItemDetails,
} from './styled-component';

const {
  requestEquipment: {
    deliveryAddress: {
      cardTitle,
      addressLine1,
      addressLine2,
      cityState,
      postalCode,
      editButton,
      warningBanner,
      country,
      notSupportedAddress,
      confirmAddressInfoTip,
    },
  },
} = en;

interface AddressSummaryProps {
  talent: EmployeeResponseDTO;
  setTalentAddress: (address: AddressDTO) => void;
  setSupportedCountry: (supportedCountry: boolean) => void;
  talentHasPerviousRequests: boolean;
}

export const AddressSummary: FC<AddressSummaryProps> = ({
  talent,
  setTalentAddress,
  setSupportedCountry,
  talentHasPerviousRequests,
}) => {
  const { data: talentAddress, isLoading: talentAddressLoading } =
    useTalentDeliveryAddress(talent?.id);

  const [isModelOpen, setIsModalOpen] = useState<boolean>(false);

  const [modalFormValues, setModalFormValues] = useState<AddressDTO>(null);

  const [supportedCountryForDeleviry, setSupportedCountryForDeleviry] =
    useState<boolean>(true);

  const updateAddress = useCallback((values: AddressDTO) => {
    setModalFormValues(values);

    setIsModalOpen(false);
  }, []);

  const { data: supportedCountries, isLoading: supportedCountriesLoading } =
    useGetHofiySupportedCountries();

  useEffect(() => {
    if (supportedCountries) {
      let supportedCountry;
      if (modalFormValues)
        supportedCountry = supportedCountries?.find(
          countryItem => countryItem.code === modalFormValues?.countryISOCode
        );
      else
        supportedCountry = supportedCountries?.find(
          countryItem => countryItem.code === talentAddress?.countryISOCode
        );

      if (supportedCountry) {
        setSupportedCountryForDeleviry(true);
        setSupportedCountry(true);
      } else {
        setSupportedCountryForDeleviry(false);
        setSupportedCountry(false);
      }
    }
  }, [
    modalFormValues,
    setSupportedCountry,
    supportedCountries,
    talentAddress?.countryISOCode,
  ]);

  useEffect(() => {
    setTalentAddress(modalFormValues || talentAddress);
  }, [modalFormValues, setTalentAddress, talentAddress]);

  if (supportedCountriesLoading && talentAddressLoading) {
    return (
      <Box
        sx={{
          height: '10rem',
          display: 'flex',
          flexDirection: 'column',
          background: palette.secondary.white,
          marginBottom: '2rem',
          padding: '4rem',
          paddingLeft: '13rem',
          borderRadius: '0.5rem',
        }}
      >
        <Spinner size={32} />
      </Box>
    );
  }

  return (
    <>
      <Card>
        <AddressHeader>
          <AddressTitleContainer>
            <AddressTitle>
              <PinDropIcon />
            </AddressTitle>

            <AddressTitle>{cardTitle}</AddressTitle>
          </AddressTitleContainer>

          <Button
            btnType="subtle"
            style={{ width: 116 }}
            prefixIcon={() => <EditIcon fontSize="small" />}
            onClick={() => {
              setIsModalOpen(true);
            }}
            disabled={talentHasPerviousRequests}
          >
            {editButton}
          </Button>
        </AddressHeader>

        <AddressItemContainer>
          <AddressItem>{addressLine1}</AddressItem>

          <AddressItemDetails>
            {modalFormValues?.street || talentAddress?.street || '-'}
          </AddressItemDetails>
        </AddressItemContainer>

        <AddressItemContainer>
          <AddressItem>{addressLine2}</AddressItem>

          <AddressItemDetails>
            {modalFormValues?.line2 || talentAddress?.line2 || '-'}
          </AddressItemDetails>
        </AddressItemContainer>

        <AddressItemContainer>
          <AddressItem>{country}</AddressItem>

          <AddressItemDetails>
            {getCountryNameByIsoCode(talentAddress?.countryISOCode)}
          </AddressItemDetails>
        </AddressItemContainer>

        <AddressItemContainer>
          <AddressItem>{cityState}</AddressItem>

          <AddressItemDetails>
            {modalFormValues?.city || talentAddress?.city || '-'}
          </AddressItemDetails>
        </AddressItemContainer>

        <AddressItemContainer>
          <AddressItem>{postalCode}</AddressItem>

          <AddressItemDetails>
            {modalFormValues?.postalCode || talentAddress?.postalCode || '-'}
          </AddressItemDetails>
        </AddressItemContainer>
      </Card>

      <Box sx={{ width: '28.75rem', marginBottom: '2rem' }}>
        {supportedCountryForDeleviry && !talentHasPerviousRequests && (
          <InfoTip text={warningBanner} />
        )}

        {supportedCountryForDeleviry && talentHasPerviousRequests && (
          <InfoTip text={confirmAddressInfoTip} />
        )}

        {!supportedCountryForDeleviry && talentAddress && (
          <InfoTip tipType="error" text={notSupportedAddress} />
        )}
      </Box>

      <AddressModalForm
        isOpen={isModelOpen}
        closeModal={() => setIsModalOpen(false)}
        selectedItem={modalFormValues || talentAddress}
        setAddressValues={updateAddress}
      />
    </>
  );
};
