import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

import { FieldLabel } from './label';

const StyledContainer = styled.div({
  width: '100%',
});

const StyledLabel = styled(FieldLabel)({
  fontSize: '0.875rem',
  fontWeight: '400',
  color: `${palette.greyscale.persianBlue}`,
  display: 'flex',
  minWidth: 150,
  marginBottom: '1.3rem',
});

const StyledFieldContainer = styled.div<{ width?: string }>(props => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  marginBottom: '2rem',
  width: props.width ? props.width : '100%',
  '@media(max-width: 480px)': {
    marginBottom: '1rem',
  },
}));

export const CenterPositionStyle = {
  display: 'flex',
  width: '100%',
  height: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
};

export { StyledContainer, StyledLabel, StyledFieldContainer };
