import React from 'react';

import { FormField, InputField } from 'workmotion-design-system';

import { en } from 'localization';

import { getCountryNameByIsoCode } from '../../get-country-name';

const {
  requestEquipment: {
    deliveryAddress: {
      formFields: {
        country: { label },
      },
    },
  },
} = en;

export const CountryField: React.FC<{ countryIsoCode: string }> = ({
  countryIsoCode,
}) => (
  <FormField label={label}>
    <InputField
      value={getCountryNameByIsoCode(countryIsoCode)}
      disabled
      name="country-field"
      inputProps={{ 'data-testid': 'country-input' }}
    />
  </FormField>
);
