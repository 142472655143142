import { Option } from 'app/shared/equipment-item';
import { en } from 'localization';
import {
  EquipmentProductDTO,
  EquipmentProductVariantDTO,
  EquipmentProductVariantPricingDTO,
} from 'types/equipment-types';

const {
  requestEquipment: { paidMonthly },
} = en;

export const getVarintsPriceRange = (
  priceList: EquipmentProductVariantPricingDTO[]
): string[] => {
  const sortedPriceList = priceList.sort((a, b) => a.amount - b.amount);
  const lowestPrice = sortedPriceList[0].amount.toFixed(2);

  const highestPrice =
    sortedPriceList[sortedPriceList.length - 1].amount.toFixed(2);

  return [`€${lowestPrice}`, `€${highestPrice}`];
};

const getVarinatsPriceList = (
  varints: EquipmentProductVariantDTO[]
): EquipmentProductVariantPricingDTO[] => {
  let priceList: EquipmentProductVariantPricingDTO[] = [];

  varints.forEach(varint => {
    varint.pricing.forEach(varintPricing => {
      priceList.push(varintPricing);
    });
  });

  return priceList;
};

export const getItemPrice = (varints: EquipmentProductVariantDTO[]): string => {
  if (varints?.length === 1 && varints[0]?.pricing?.length == 1)
    return `${varints[0].pricing[0].currency} ${varints[0].pricing[0].amount}`;

  let priceList: EquipmentProductVariantPricingDTO[] = [];

  if (varints) priceList = getVarinatsPriceList(varints);

  const [lowstPrice, highestPrice] = getVarintsPriceRange(priceList);

  return `${lowstPrice} to ${highestPrice}`;
};

export const mapEquipmentItemToOptionItem = (
  item: EquipmentProductDTO
): Option => ({
  label: item?.name,
  value: item?.name,
  descriptionHint: paidMonthly,
  description: getItemPrice(item.variants),
  imageSrc: item?.images[0],
  id: item?.name,
  category: item?.category,
});
