import React, { useCallback } from 'react';

import { useField } from 'formik';
import { FormField } from 'workmotion-design-system';

import { TalentSearch } from 'app/shared/talent-search';
import { en } from 'localization';
import { EmployeeResponseDTO } from 'types/employee';

const TalentNameField: React.FC<{
  setEmployee?: (employee: EmployeeResponseDTO) => void;
  selectedEmployee?: EmployeeResponseDTO;
  isEditing?: boolean;
}> = ({ setEmployee, selectedEmployee, isEditing = false }) => {
  const {
    requestEquipment: {
      fields: {
        talent: { fieldName, label },
      },
    },
  } = en;

  const [, meta, helpers] = useField(fieldName);
  const showError = !!(meta.touched && meta.error);

  const onSelect = useCallback(
    (employee: EmployeeResponseDTO) => {
      helpers.setValue(employee.id);
      setEmployee(employee);
    },
    [helpers, setEmployee]
  );

  const onRemove = useCallback(() => {
    helpers.setValue('');
    setEmployee(null);
  }, [helpers, setEmployee]);

  return (
    <div style={{ width: '100%' }}>
      <FormField label={label} showError={showError} errorMessage={meta.error}>
        <TalentSearch
          onSelect={onSelect}
          isDisabled={!isEditing}
          onRemove={onRemove}
          selectedTalent={selectedEmployee || null}
          showError={showError}
        />
      </FormField>
    </div>
  );
};

export { TalentNameField };
