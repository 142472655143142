import React, { FC, useCallback } from 'react';

import { Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { LayoutWrapper } from 'workmotion-design-system';

import { en } from 'localization';
import { useTalentEquipment } from 'networking/equipment-api/equipment-requests';
import { AddressDTO, EmployeeResponseDTO } from 'types/employee';
import { EquipmentProductDTO } from 'types/equipment-types';

import { EquipmentForm } from './equipment-form';
import { LayoutCaption } from './layout-caption';
import { Description, Title } from './request-equipment-styled';
import {
  SelectedProductsContext,
  useSelectedProducts,
} from './selected-products-context';

const {
  requestEquipment: {
    layoutTitle,
    wrapperTitle,
    layoutDescription,
    firstStepDescription,
  },
} = en;

export const RequestEquipment: FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const {
    selectedEmployee,
    talentAddress,
  }: {
    selectedEmployee?: EmployeeResponseDTO;
    talentAddress: AddressDTO;
  } = state;

  const selectedProducts = useSelectedProducts();

  const { data: equipmentList, isInitialLoading } = useTalentEquipment(
    selectedEmployee?.id,
    talentAddress?.countryISOCode
  );

  const handleSubmit = useCallback(
    ({ products }: { products: EquipmentProductDTO[] }) => {
      navigate('/equipment/request-equipment/place-order', {
        state: {
          productsList: [...products],
          selectedEmployee: selectedEmployee,
          talentAddress: talentAddress,
        },
      });
    },
    [navigate, selectedEmployee, talentAddress]
  );

  return (
    <SelectedProductsContext.Provider value={selectedProducts}>
      <LayoutWrapper
        layoutCaption={
          <LayoutCaption title={layoutTitle} description={layoutDescription} />
        }
        onLogoClick={() => navigate(-1)}
      >
        <Box sx={{ width: '43.75rem' }}>
          <Title data-cy="request-equipment-title-step2">{wrapperTitle}</Title>

          <Description>{firstStepDescription}</Description>
        </Box>

        <EquipmentForm
          isInitialLoading={isInitialLoading}
          equipmentsOption={equipmentList}
          handleSubmit={handleSubmit}
          selectedEmployee={selectedEmployee}
        />
      </LayoutWrapper>
    </SelectedProductsContext.Provider>
  );
};
