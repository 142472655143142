import React from 'react';

import { Select } from 'workmotion-design-system';
import type { SelectOption } from 'workmotion-design-system';

import { en } from 'localization';
import {
  ActiveStateStatusEnum,
  ReturnStateStatusEnum,
} from 'types/equipment-types';

const generateOptionList = (
  statusEnum: typeof ActiveStateStatusEnum | typeof ReturnStateStatusEnum
): SelectOption[] =>
  Object.values(statusEnum).map(
    status =>
      ({ value: status, label: status.replace('_', ' ') } as SelectOption)
  );

export const StatusFilter: React.FC<{
  active: boolean;
  value: string;
  onChange: (val: string) => void;
}> = ({ active, value, onChange }) => {
  const selectOptions = generateOptionList(
    active ? ActiveStateStatusEnum : ReturnStateStatusEnum
  );

  const {
    filters: { statusPlaceholder },
  } = en;

  return (
    <Select
      value={value}
      options={selectOptions}
      onChange={onChange}
      placeholder={statusPlaceholder}
    />
  );
};
