import React, { useCallback } from 'react';

import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Button, Spinner } from 'workmotion-design-system';

import { en } from 'localization';
import { EmployeeResponseDTO } from 'types/employee';
import { EquipmentProductDTO } from 'types/equipment-types';

import { FormikProductList } from './formik-product-list';
import { TalentNameField } from './formik-talent-name';
import {
  requestEquipmentInitialValues,
  requestEquipmentSchema,
} from './formik-values-setup';
import { Card, Footer } from './request-equipment-styled';
import { Content } from '../layout-content';

interface EquiomentFormProps {
  handleSubmit: ({
    talent,
    products,
  }: {
    products: EquipmentProductDTO[];
    talent: string;
  }) => void;
  selectedEmployee?: EmployeeResponseDTO;
  equipmentsOption: EquipmentProductDTO[];
  isInitialLoading: boolean;
}

const {
  requestEquipment: {
    buttons: { primaryButton, secondryButton },
  },
} = en;

export const EquipmentForm: React.FC<EquiomentFormProps> = ({
  handleSubmit,
  selectedEmployee,
  equipmentsOption,
  isInitialLoading,
}) => {
  const navigate = useNavigate();

  const submitForm = useCallback(
    values => {
      handleSubmit(values);
    },
    [handleSubmit]
  );

  return (
    <Formik
      initialValues={requestEquipmentInitialValues()}
      onSubmit={submitForm}
      validationSchema={requestEquipmentSchema}
    >
      {({ isSubmitting }) => (
        <>
          <Content direction={'center'}>
            <Form style={{ width: '100%' }} role="form">
              <Card>
                <TalentNameField
                  isEditing={false}
                  selectedEmployee={selectedEmployee}
                />
              </Card>

              {(isInitialLoading ||
                (!isInitialLoading && equipmentsOption?.length > 0)) && (
                <Card style={{ padding: 0 }}>
                  {isInitialLoading && (
                    <div style={{ padding: '2rem' }}>
                      <Spinner
                        data-testid="isLoading"
                        size={32}
                        style={{ marginBottom: '1rem' }}
                      />
                    </div>
                  )}

                  {!isInitialLoading && equipmentsOption?.length > 0 && (
                    <FormikProductList options={equipmentsOption} />
                  )}
                </Card>
              )}

              <Footer>
                <Button
                  btnType={'secondaryGrey'}
                  onClick={() => navigate(-1)}
                  data-cy="cancel-btn"
                >
                  {secondryButton}
                </Button>

                <Button
                  btnType={'default'}
                  type={'submit'}
                  data-cy="submit-btn"
                >
                  {!isSubmitting && primaryButton}

                  {isSubmitting && <Spinner size={20} />}
                </Button>
              </Footer>
            </Form>
          </Content>
        </>
      )}
    </Formik>
  );
};
