import React, { useCallback } from 'react';

import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import { Button, Spinner } from 'workmotion-design-system';

import { useSelectedCompanyId } from 'app/manage-view/helpers/use-company-id';
import { en } from 'localization';
import { useGetEquipmentReport } from 'networking/equipment-api/equipment-requests';
import { GetEquipmentQueryParameters } from 'types/equipment-types';

const {
  managedView: { exportBtn },
} = en;

export const ExportReportBtn: React.FC<{
  queryParams: GetEquipmentQueryParameters;
  isActive: boolean;
}> = ({ queryParams, isActive }) => {
  const { mutate, isLoading } = useGetEquipmentReport();
  const { selectedCompanyId } = useSelectedCompanyId();

  const downloadReport = useCallback(() => {
    mutate({
      companyId: selectedCompanyId,
      state: isActive ? 'ACTIVE' : 'RETURN',
      ...queryParams,
    });
  }, [mutate, queryParams, selectedCompanyId, isActive]);

  return (
    <Button
      btnType="subtle"
      prefixIcon={() =>
        isLoading ? <Spinner size={16} /> : <DownloadRoundedIcon />
      }
      data-testid="export-btn"
      onClick={downloadReport}
    >
      {exportBtn}
    </Button>
  );
};
