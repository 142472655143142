import React, { useCallback, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  Checkbox,
  LayoutWrapper,
  palette,
  Spinner,
  InputField,
} from 'workmotion-design-system';

import { FieldErrorMessage } from 'app/shared/label';
import { en } from 'localization';
import { useTalentEquipmentOrdering } from 'networking/equipment-api/equipment-requests';
import { AddressDTO, EmployeeResponseDTO } from 'types/employee';
import {
  EquipmentOrderItemDTO,
  EquipmentProductDTO,
  EquipmentProductVariantDTO,
  OrderEquipmentDeliveryAddressDTO,
} from 'types/equipment-types';

import { CostCard } from './cost-card';
import { EquipmentCardItem } from './equipment-item-card';
import { Card, CheckBoxContainer, Footer } from './place-order-styled';
import { LayoutCaption } from '../equipment-details-step/layout-caption';
import {
  Description,
  Title,
} from '../equipment-details-step/request-equipment-styled';

export interface ProductPostObject {
  productName: string;
  orderItems: EquipmentOrderItemDTO;
  costMonthly: string;
}

export const PlaceOrderStep: React.FC = () => {
  const {
    requestEquipment: {
      wrapperTitle,
      placeOrderDescription,
      placeOrderStep: {
        buttons: { primaryButton, secondryButton },
        checkBoxLabel,
        termsAndCondition,
        termsAndConditionUrl,
        termsAndConditionValidationMessage,
        layoutTitle,
        layoutDescription,
      },
      fields: {
        talent: { label },
      },
    },
  } = en;

  const navigate = useNavigate();
  const { state } = useLocation();

  const {
    productsList,
    selectedEmployee,
    talentAddress,
  }: {
    productsList: EquipmentProductDTO[];
    selectedEmployee?: EmployeeResponseDTO;
    talentAddress: AddressDTO;
  } = state;

  const [renderedProducts, setRenderedProducts] =
    useState<EquipmentProductDTO[]>(productsList);

  const [orderItems, setOrderItems] = useState<ProductPostObject[]>([]);

  const [termsAndConditionChecked, settermsAndConditionChecked] =
    useState<boolean>(false);

  const [showError, setShowError] = useState<boolean>(false);

  const onRemoveItem = useCallback(
    (
      productItem: EquipmentProductDTO,
      selectedVariant: EquipmentProductVariantDTO
    ): void => {
      setRenderedProducts(current => {
        const productTobeRemovedIndex = renderedProducts.findIndex(
          item => item.name === productItem.name
        );

        if (productTobeRemovedIndex > -1)
          current.splice(productTobeRemovedIndex, 1);

        return [...current];
      });

      setOrderItems(current => {
        const varinatToRemoveIndex = orderItems.findIndex(
          item => item.orderItems.variantId === selectedVariant.id
        );

        if (varinatToRemoveIndex > -1) current.splice(varinatToRemoveIndex, 1);

        return [...current];
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate, orderItems, renderedProducts, renderedProducts?.length]
  );

  const onSelectVariantAndRentalPeriod = useCallback(
    (
      selectedOrderItem: EquipmentOrderItemDTO,
      productItem: EquipmentProductDTO,
      costMonthly: any
    ) => {
      const productObject: ProductPostObject = {
        productName: productItem.name,
        orderItems: selectedOrderItem,
        costMonthly: costMonthly,
      };

      const isOrderItemAlreadyExisit = orderItems.find(
        item => item.productName === productItem.name
      );

      if (isOrderItemAlreadyExisit) {
        setOrderItems(current => {
          const itemIndex = current.findIndex(
            item => item.productName === productItem.name
          );

          current[itemIndex] = {
            costMonthly: costMonthly,
            orderItems: selectedOrderItem,
            productName: productItem.name,
          };

          return [...current];
        });
      } else {
        setOrderItems(current => [...current, productObject]);
      }
    },
    [orderItems]
  );

  const { isLoading, mutate } = useTalentEquipmentOrdering(
    selectedEmployee?.id,
    orderItems.map(item => item.orderItems),
    talentAddress as OrderEquipmentDeliveryAddressDTO
  );

  const submitOrder = useCallback(() => {
    if (termsAndConditionChecked) mutate();
    else {
      setShowError(true);
    }
  }, [mutate, termsAndConditionChecked]);

  useEffect(() => {
    if (renderedProducts.length === 0) navigate(-1);
  }, [navigate, renderedProducts?.length]);

  return (
    <LayoutWrapper
      layoutCaption={
        <LayoutCaption title={layoutTitle} description={layoutDescription} />
      }
      onLogoClick={() => navigate('/')}
    >
      <Box sx={{ width: '28.75rem' }}>
        <Title data-cy="request-equipment-title-step3">{wrapperTitle}</Title>

        <Description>{placeOrderDescription}</Description>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '2rem 1.5rem 1.5rem 1.5rem',
          width: '28.75rem',
          background: `${palette.secondary.white}`,
          borderRadius: '0.5rem',
        }}
      >
        <div style={{ width: '100%' }}>
          <InputField
            label={label}
            value={`${selectedEmployee.firstName} ${selectedEmployee.lastName}`}
            disabled
          />
        </div>
      </Box>

      <Card>
        {renderedProducts?.map((productItem: EquipmentProductDTO) => (
          <EquipmentCardItem
            key={productItem.variants[0].id}
            productItem={productItem}
            onRemoveItem={onRemoveItem}
            onSelectVariantAndRentalPeriod={onSelectVariantAndRentalPeriod}
          />
        ))}

        <CostCard products={orderItems} />

        <CheckBoxContainer>
          <Checkbox
            id="placeOrder-checkBox"
            data-cy="accept-t-and-c-checkbox"
            labelStyle={{
              fontSize: '0.875rem',
              fontWeight: 400,
              display: 'inline',
              color: palette.greyscale.UCLABlue,
            }}
            checkboxSize={18}
            onChange={() => settermsAndConditionChecked(prev => !prev)}
            checked={termsAndConditionChecked}
          >
            <p>
              {checkBoxLabel}

              <a
                style={{
                  color: palette.primary.cerisePink,
                }}
                href={termsAndConditionUrl}
                target="_blank"
                rel="noreferrer"
                data-cy="terms-and-conditions-link"
              >
                {termsAndCondition}
              </a>
            </p>

            {showError && !termsAndConditionChecked && (
              <FieldErrorMessage>
                {termsAndConditionValidationMessage}
              </FieldErrorMessage>
            )}
          </Checkbox>
        </CheckBoxContainer>

        <Footer>
          <Button
            btnType={'secondaryGrey'}
            onClick={() => navigate(-1)}
            data-cy="cancel-btn"
          >
            {secondryButton}
          </Button>

          <Button
            btnType={'default'}
            type={'button'}
            data-cy="place-order-btn"
            onClick={submitOrder}
            disabled={isLoading}
          >
            {!isLoading && primaryButton}

            {isLoading && <Spinner size={20} />}
          </Button>
        </Footer>
      </Card>
    </LayoutWrapper>
  );
};
