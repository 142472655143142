import React, { useCallback, useEffect, useState } from 'react';

import { EquipmentProductDTO } from 'types/equipment-types';

import { EquipmentCard } from '../../shared/equipment-item';

export const SelectableEquipmentCard: React.FC<{
  option: EquipmentProductDTO;
  selectedOptions: EquipmentProductDTO[];
  highlightErrorInCheckbox: boolean;
  selectProduct: (value: EquipmentProductDTO) => void;
  deselectProduct: (value: EquipmentProductDTO) => void;
}> = ({
  option,
  selectedOptions,
  highlightErrorInCheckbox,
  selectProduct,
  deselectProduct,
}) => {
  const [isSelected, setIsSelected] = useState(
    selectedOptions.find(o => o.id === option.id) !== undefined
  );

  useEffect(() => {
    setIsSelected(selectedOptions.find(o => o.id === option.id) !== undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  const check = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (isSelected) {
        deselectProduct(option);
      } else {
        selectProduct(option);
      }
      e.preventDefault();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSelected, selectedOptions]
  );

  return (
    <EquipmentCard
      showCheckBox
      option={option}
      selected={isSelected}
      hasError={highlightErrorInCheckbox}
      onChangeTextBox={check}
    />
  );
};
