import React from 'react';

import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded';
import styled from 'styled-components';
import { Fallback, IconBlob } from 'workmotion-design-system';

import { en } from 'localization';

const {
  managedView: {
    emptyEquipmentList: { title, message },
  },
} = en;

const StyledFallbackWrapper = styled.div({
  height: '50vh',
});

export const EmptyList: React.FC = () => (
  <StyledFallbackWrapper>
    <Fallback
      content={
        <div
          style={{
            marginTop: '2rem',
          }}
          data-testid="no-equipment-list"
        >
          <IconBlob icon={<DevicesRoundedIcon />} type="success" />
        </div>
      }
      title={title}
      subtitle={message}
    />
  </StyledFallbackWrapper>
);
