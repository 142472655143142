import React from 'react';

import type { ChipColor } from 'workmotion-design-system';
import { Timeline, palette } from 'workmotion-design-system';

import {
  ActiveStateStatus,
  ReturnStateStatus,
  ActiveStateStatusEnum,
  ReturnStateStatusEnum,
} from 'types/equipment-types';

import { StatusBadge } from './status-badge';
import { ActiveStatusColors, ReturnStatusColors } from './status-colors';

export const chipColors = {
  green: {
    background: palette.secondary.softMint,
    color: palette.semantic.persianGreen,
  },
  fullGreen: {
    background: palette.primary.genericViridian,
    color: palette.secondary.white,
  },

  curry: {
    background: palette.secondary.ivoryWillow,
    color: palette.semantic.lemonCurry,
  },

  gray: {
    background: palette.greyscale.ghostWhite,
    color: palette.greyscale.UCLABlue,
  },

  red: {
    background: palette.semantic.fairPink,
    color: palette.semantic.sunsetOrange,
  },

  blue: {
    background: palette.semantic.babyBlue,
    color: palette.semantic.toyBlue,
  },
};

const getStatusCheckpoint = (status: ActiveStateStatus, statusEnum, colors) => {
  let allStatuses = Object.values(statusEnum);
  allStatuses.pop();
  const lastCompletedIndex = allStatuses.indexOf(statusEnum[status]);

  return allStatuses.map((currentStatus, index) => ({
    name: (currentStatus as string).replace('_', ' '),
    isCompleted: lastCompletedIndex >= index,
    labelStyles: chipColors[colors[status]],
  }));
};

type TimelineActiveProps = {
  status: ActiveStateStatus;
  statusColors: { [key in ActiveStateStatusEnum]: ChipColor };
  statusEnum: typeof ActiveStateStatusEnum;
};
type TimelineReturnProps = {
  status: ReturnStateStatus;
  statusColors: { [key in ReturnStateStatusEnum]: ChipColor };
  statusEnum: typeof ReturnStateStatusEnum;
};

const TimelineStatus: React.FC<TimelineActiveProps | TimelineReturnProps> = ({
  status,
  statusColors,
  statusEnum,
}) => {
  if (status === 'CANCELLED')
    return (
      <StatusBadge
        label={status}
        statusColor={statusColors[status]}
        chipStyle={{ width: '190px' }}
        data-cy={`${status}-status-timeline`}
      />
    );

  return (
    <div style={{ width: '190px' }} data-cy={`${status}-status-timeline`}>
      <Timeline
        checkPoints={getStatusCheckpoint(
          statusEnum[status],
          statusEnum,
          statusColors
        )}
      />
    </div>
  );
};

export const ActiveTimelineStatus: React.FC<{ status: ActiveStateStatus }> = ({
  status,
}) => (
  <TimelineStatus
    status={status}
    statusColors={ActiveStatusColors}
    statusEnum={ActiveStateStatusEnum}
  />
);

export const ReturnTimelineStatus: React.FC<{ status: ReturnStateStatus }> = ({
  status,
}) => (
  <TimelineStatus
    status={status}
    statusColors={ReturnStatusColors}
    statusEnum={ReturnStateStatusEnum}
  />
);
