import React from 'react';

import { Modal, ModalButtonsFooter } from 'workmotion-design-system';

import { en } from 'localization';
import { EquipmentResponseDTO } from 'types/equipment-types';

import { ActivePreviewDetails } from './active-preview-details';
import { ReturnPreviewDetails } from './return-preview-details';

const {
  managedView: {
    shipmentPreview: { title, closeBtn, inShipmentTitle },
  },
} = en;

type ModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  selectedEquipment: EquipmentResponseDTO;
};

export const PreviewEquipment: React.FC<ModalProps> = ({
  isOpen,
  closeModal,
  selectedEquipment,
}) =>
  selectedEquipment ? (
    <Modal
      isOpen={isOpen}
      header={
        selectedEquipment.state === 'ACTIVE' &&
        selectedEquipment.activeStateStatus === 'DELIVERED'
          ? title
          : inShipmentTitle
      }
      closeModal={closeModal}
      data-testid="equipment-preview"
      footer={
        <ModalButtonsFooter
          placement="flex-end"
          buttonsInfo={[
            {
              onClick: closeModal,
              text: closeBtn,
              dataCy: 'shipment-details-close-button',
            },
          ]}
        />
      }
    >
      {selectedEquipment.state === 'ACTIVE' ? (
        <ActivePreviewDetails selectedEquipment={selectedEquipment} />
      ) : (
        <ReturnPreviewDetails selectedEquipment={selectedEquipment} />
      )}
    </Modal>
  ) : (
    <></>
  );
