import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

export const ModalButtonsFooterCustomStyle = {
  padding: '2rem 0 0',
  margin: '0 0',
  borderTop: `1px solid ${palette.greyscale.antiFlashWhite}`,
};

export const MainContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '2rem',
});
