import React from 'react';

import { Box } from '@mui/material';
import type { SxProps } from '@mui/material';

export const Content: React.FC<{
  direction: 'center' | 'start';
  style?: SxProps;
}> = ({ direction, style = {}, children }) => (
  <Box
    sx={{
      padding: '0 2rem',
      alignItems: direction,
      minHeight: '70vh',
      flex: 1,
      ...style,
    }}
  >
    {children}
  </Box>
);
