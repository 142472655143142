import { useMemo } from 'react';

import { useSearchParams } from 'react-router-dom';
interface UseUrlParamsReturn {
  equipmentIds: string[];
}

export const useUrlParams = (): UseUrlParamsReturn => {
  let [queryParams] = useSearchParams();

  const equipmentIds = useMemo(
    () => queryParams.getAll('equipmentIds'),
    [queryParams]
  );

  return { equipmentIds: equipmentIds };
};
