import React from 'react';

import { useOktaAuth } from '@okta/okta-react';
import { FeatureFlagsWrapper } from '@workmotion/feature-flags-library';
import { Navigate, Route } from 'react-router-dom';
import { Spinner } from 'workmotion-design-system';

import { EmployeeView } from './employee-view';
import { ManagedView } from './manage-view';
import { DeliveryAddressStep } from './request-equipment/delivery-address-step';
import { RequestEquipment } from './request-equipment/equipment-details-step';
import { PlaceOrderStep } from './request-equipment/place-order-step';
import { useUserIdentity } from '../authentication/use-user-identity';
import { SentryRoutes } from '../tracking/routes';

const AppRoutes: React.FC = () => {
  const identity = useUserIdentity();

  if (!identity.isAuthenticated) {
    return (
      <SentryRoutes>
        <Route path="*" element={<Navigate to="/" replace />} />
      </SentryRoutes>
    );
  }

  if (identity.isEmployee) {
    return (
      <div data-testid="employee-route">
        <SentryRoutes>
          <Route path="/equipment" element={<EmployeeView />} />
        </SentryRoutes>
      </div>
    );
  }

  return (
    <div data-testid="manage-route">
      <SentryRoutes>
        <Route path="/equipment/*" element={<ManagedView />} />

        <Route
          path="/equipment/request-equipment"
          element={<DeliveryAddressStep />}
        />

        <Route
          path="/equipment/request-equipment/details"
          element={<RequestEquipment />}
        />

        <Route
          path="/equipment/request-equipment/place-order"
          element={<PlaceOrderStep />}
        />
      </SentryRoutes>
    </div>
  );
};

export const RoutesWithFeatureFlags: React.FC = () => {
  const identity = useUserIdentity();
  const { authState } = useOktaAuth();

  if (authState == null) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        data-testid="appRoutes-loading"
      >
        <Spinner />
      </div>
    );
  }

  return (
    <FeatureFlagsWrapper
      userInfo={identity.isAuthenticated ? identity.featureFlagsIdentity : null}
    >
      <AppRoutes />
    </FeatureFlagsWrapper>
  );
};
