import React from 'react';

import { ActionDropdown } from 'app/shared/action-dropdown';
import {
  previewItem,
  returnItem,
} from 'app/shared/action-dropdown/action-items';
import { DropdownItemProps } from 'app/shared/action-dropdown/dropdown';
import {
  AuthenticatedIdentity,
  useUserIdentity,
} from 'authentication/use-user-identity';

export const ActiveActions: React.FC<{
  showReturn: boolean;
  handlePreview: () => void;
  handleReturn?: () => void;
}> = ({ handlePreview, handleReturn, showReturn }) => {
  const { isHrManager, isCompanyAdmin } =
    useUserIdentity() as AuthenticatedIdentity;

  const items = [
    {
      ...previewItem,
      onClick: handlePreview,
    },
  ];

  if ((isHrManager || isCompanyAdmin) && showReturn) {
    items.push({
      ...returnItem,
      onClick: handleReturn,
    });
  }

  return <ActionDropdown items={items} />;
};

export const ReturnActions: React.FC<{
  handlePreview: () => void;
}> = ({ handlePreview }) => {
  const items: DropdownItemProps[] = [
    {
      ...previewItem,
      onClick: handlePreview,
    },
  ];

  return <ActionDropdown items={items} />;
};
