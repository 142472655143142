import React, { useState } from 'react';

import { InfoIcon, palette, Drawer } from 'workmotion-design-system';

import { StyledSideMenuContainer } from './sidemenu-styled-components';

interface InfoSideMenuProps {
  sideMenuTitle: string;
  children: React.ReactNode;
}

const InfoSideMenu: React.FC<InfoSideMenuProps> = ({
  sideMenuTitle,
  children,
}) => {
  const [isMenuOpened, setMenuOpened] = useState(false);

  return (
    <>
      <InfoIcon
        height="0.875rem"
        width="0.875rem"
        color={palette.greyscale.sharkMouth}
        onClick={() => setMenuOpened(true)}
        cursor="pointer"
        data-testid="status-descr-icon"
      />

      <Drawer
        anchor="right"
        open={isMenuOpened}
        onClose={() => setMenuOpened(false)}
        title={sideMenuTitle}
      >
        <StyledSideMenuContainer>{children}</StyledSideMenuContainer>
      </Drawer>
    </>
  );
};

export { InfoSideMenu };
