import { useCallback, useEffect, useState } from 'react';

import {
  AuthenticatedIdentity,
  useUserIdentity,
} from 'authentication/use-user-identity';
import { useEmployeeRequests } from 'networking/employee-api/employee-network-requests';
import { ApiPaginationData, QueryParameters } from 'types';
import { EmployeeResponseDTO, GetEmployeesParams } from 'types/employee';

export const useSearchTalents = (searchValue: string) => {
  const [talents, setTalents] = useState<EmployeeResponseDTO[]>([]);

  const [params, setParams] = useState<GetEmployeesParams>(null);

  const [pageInfo, setPageInfo] = useState<ApiPaginationData>();

  const { data: employeesData, isFetching: isLoading } = useEmployeeRequests(
    params as QueryParameters
  );

  const { companyId } = useUserIdentity() as AuthenticatedIdentity;

  useEffect(() => {
    const allParams = {
      size: 25,
      page: 0,
      includeState: false,
      includeSummary: false,
      orderBy: 'st_filter.rank',
      direction: 'desc',
      companyId: companyId ? companyId : '',
      employeeStatuses: 'CONTRACT_SIGNED',
    };

    setParams({ searchTerm: searchValue, ...allParams });
  }, [searchValue, companyId]);

  const onSuccess = useCallback(responseData => {
    if (responseData?.content) {
      setPageInfo(responseData?.page);

      if (responseData?.page?.first) {
        setTalents(responseData.content);
      } else
        setTalents(prevTalents => [...prevTalents, ...responseData.content]);
    }
  }, []);

  const getNextPage = useCallback(() => {
    if (!pageInfo?.last) {
      setParams(prevParams => ({
        ...prevParams,
        page: (pageInfo?.number || 0) + 1,
      }));
    }
  }, [setParams, pageInfo]);

  useEffect(() => {
    onSuccess(employeesData);
  }, [employeesData, onSuccess]);

  return { isLoading, talents, getNextPage };
};
