import React, { useCallback, useEffect } from 'react';

import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { addBreadcrumb, captureException } from '@sentry/react';
import { useNavigate } from 'react-router-dom';

const ISSUER = window.env.OKTA_ISSUER;
const CLIENT_ID = window.env.OKTA_CLIENT_ID;
const REDIRECT_URI = `${window.location.origin}/login/callback`;

function initializeOktaInstance() {
  return new OktaAuth({
    issuer: ISSUER,
    clientId: CLIENT_ID,
    redirectUri: REDIRECT_URI,
    postLogoutRedirectUri: `${window.location.origin}/login`,
    tokenManager: { autoRenew: true, expireEarlySeconds: 4.5 * 60 },
    scopes: ['email', 'openid', 'offline_access', 'profile'],
  });
}

const oktaAuth = initializeOktaInstance();

export const AuthenticationContext: React.FC = ({ children }) => {
  const navigate = useNavigate();

  const navigateToOrigin = async (_oktaAuth: OktaAuth, originalUri: string) =>
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));

  const oktaLifecycleLog = useCallback(
    eventName => (tokenName: string) => {
      addBreadcrumb({
        message: `Okta event ${eventName} happen!`,
        data: { tokenName },
        level: 'log',
      });
    },
    []
  );

  useEffect(() => {
    oktaAuth.tokenManager.on('error', error => {
      captureException(error);
    });

    oktaAuth.tokenManager.on('renewed', oktaLifecycleLog('renewed'));
    oktaAuth.tokenManager.on('added', oktaLifecycleLog('added'));
    oktaAuth.tokenManager.on('expired', oktaLifecycleLog('expired'));
    oktaAuth.tokenManager.on('removed', oktaLifecycleLog('removed'));

    return () => {
      addBreadcrumb({ message: 'Stopping okta!', level: 'log' });
    };
  }, [oktaLifecycleLog]);

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={navigateToOrigin}>
      {children}
    </Security>
  );
};
