//this is a copy-pasting solution from onboarding-frontend and we use it in contract changes
export const formatUTCDate = (dateIso: string) => {
  if (!dateIso) return null;
  const date = new Date(Date.parse(dateIso));

  const options: Intl.DateTimeFormatOptions = {
    timeZone: 'UTC',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };

  const formatter = new Intl.DateTimeFormat('en-GB', options);

  return formatter.format(date).toUpperCase();
};
