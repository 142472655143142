import React from 'react';

import { DateCell } from 'app/manage-view/equipment-list/cells/date-cell';
import { en } from 'localization';
import { EquipmentResponseDTO } from 'types/equipment-types';

import { PreviewItem } from './preview-item';
import { columnViewContainerStyle } from './styled-components';
import { TrackingLink } from './tracking-link';
import { StatusBadge } from '../../cells/status-badge';
import { ReturnStatusColors } from '../../cells/status-colors';

const {
  managedView: {
    shipmentPreview: { details },
  },
} = en;

export const ReturnPreviewDetails: React.FC<{
  selectedEquipment: EquipmentResponseDTO;
}> = ({ selectedEquipment }) => (
  <>
    <PreviewItem
      label={details.status}
      value={
        <StatusBadge
          label={selectedEquipment.returnStateStatus.replace('_', ' ')}
          statusColor={ReturnStatusColors[selectedEquipment.returnStateStatus]}
          data-testid={`${selectedEquipment.returnStateStatus}-info-badge`}
          data-cy={`${selectedEquipment.returnStateStatus}-info-badge`}
        />
      }
    />

    <PreviewItem
      label={details.returnReason}
      value={selectedEquipment.returnDetails?.reason}
      attrs={{
        item: { 'data-testid': 'return-reason-item' },
        value: { 'data-cy': 'return-reason-value' },
        label: { 'data-cy': 'return-reason-label' },
      }}
    />

    {selectedEquipment.returnStateStatus === 'COMPLETED' && (
      <PreviewItem
        label={details.completedDate}
        value={<DateCell date={selectedEquipment.returnDetails?.completedAt} />}
        attrs={{
          item: { 'data-testid': 'completed-date-item' },
          value: { 'data-cy': 'completed-date-value' },
          label: { 'data-cy': 'completed-date-label' },
        }}
      />
    )}

    {selectedEquipment.returnStateStatus !== 'COMPLETED' && (
      <PreviewItem
        label={details.collectionDate}
        value={<DateCell date={selectedEquipment.returnDetails?.returnDate} />}
        attrs={{
          item: { 'data-testid': 'return-date-item' },
          value: { 'data-cy': 'return-date-value' },
          label: { 'data-cy': 'return-date-label' },
        }}
      />
    )}

    {selectedEquipment.returnStateStatus !== 'COMPLETED' && (
      <PreviewItem
        label={details.courier}
        value={selectedEquipment.returnDetails?.shipment?.courier}
        attrs={{
          item: { 'data-testid': 'courier-item' },
          value: { 'data-cy': 'courier-value' },
          label: { 'data-cy': 'courier-label' },
        }}
      />
    )}

    {selectedEquipment.returnStateStatus !== 'COMPLETED' && (
      <PreviewItem
        label={details.trackingLink}
        value={
          <TrackingLink
            link={selectedEquipment.returnDetails?.shipment?.trackingLink}
          />
        }
        attrs={{
          item: { 'data-testid': 'tracking-link-item' },
          value: { 'data-cy': 'tracking-link-value' },
          label: { 'data-cy': 'tracking-link-label' },
        }}
      />
    )}

    <PreviewItem
      label={details.notes}
      value={selectedEquipment.returnDetails?.notes}
      containerStyle={columnViewContainerStyle}
      attrs={{
        item: { 'data-testid': 'notes-item' },
        value: { 'data-cy': 'notes-value' },
        label: { 'data-cy': 'notes-label' },
      }}
    />
  </>
);
