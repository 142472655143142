import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

export const CostContainer = styled.div({
  width: '28.75rem',
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  justifyContent: 'start',
  gap: '1.5rem',
  marginBottom: '1rem',
});

export const Title = styled.p({
  fontSize: '0.875rem',
  fontWeight: 600,
  textTransform: 'uppercase',
  color: palette.greyscale.persianBlue,
});

export const ProductNameRow = styled.p({
  fontSize: '0.875rem',
  color: palette.greyscale.UCLABlue,
});

export const PriceRow = styled.p({
  fontSize: '0.875rem',
  color: palette.greyscale.persianBlue,
});

export const TotalPriceContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: '1rem',
  borderRadius: '0.5rem',
  backgroundColor: palette.secondary.softMint,
  fontSize: '0.875rem',
  fontWeight: 600,
  color: palette.primary.genericViridian,
});
