import { useCallback, useEffect, useMemo, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import type { SelectOption } from 'workmotion-design-system';

import { QueryParameters } from 'types';

import { getQueryParams } from './url';

export const SUPPORTED_FILTERS = [
  'page',
  'size',
  'orderBy',
  'direction',
  'talentId',
  'companyId',
  'state',
  'activeStatus',
  'returnStatus',
];

export const DEFAULT_PAGE_SIZE_OPTIONS: SelectOption[] = [
  { value: '5', label: '5' },
  { value: '10', label: '10' },
  { value: '20', label: '20' },
];

export const DEFAULT_CURRENT_PAGE = 1;

export const DEFAULT_PAGE_SIZE = 10;

interface MapFilter {
  [key: string]: string | Array<string>;
}

interface UsePaginationReturn {
  filters?: MapFilter;
  currentPage: number;
  requestParams: QueryParameters;
  currentPageSize: number;
  updateURL?: () => void;
  handleChangePage: (newPage: number) => void;
  handleChangePageSize: (newPageSize: number) => void;
  handleChangeFilter: (name: string, value: string) => void;
  handleChangeFilters?: (filters: MapFilter) => void;
  removeFilter?: (name: string) => void;
  removeFilters?: (name: string[]) => void;
  removeAllFilters?: () => void;
}

export const usePagination = (pageSize?: number): UsePaginationReturn => {
  const location = useLocation();
  const navigate = useNavigate();
  const [filters, setFilters] = useState<MapFilter>({});

  const urlParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const paramObject = Object.fromEntries(urlParams as any);

  const currentPageSize = useMemo(
    () => Number(urlParams.get('size')) || pageSize || DEFAULT_PAGE_SIZE,
    [urlParams, pageSize]
  );

  const queryParams: QueryParameters = useMemo(
    () => getQueryParams(urlParams, SUPPORTED_FILTERS, currentPageSize),
    [urlParams, currentPageSize]
  );

  const updateURL = useCallback(() => {
    // remove explicit page (if default) for cleaner url (getQueryParams() will default to page DEFAULT_CURRENT_PAGE)

    if (urlParams.get('page') === `${DEFAULT_CURRENT_PAGE}`) {
      urlParams.delete('page');
    }

    navigate({
      pathname: location.pathname,
      search: `?${urlParams}`,
    });
  }, [navigate, location.pathname, urlParams]);

  const currentPage = useMemo(
    () => Number(urlParams.get('page')) || DEFAULT_CURRENT_PAGE,
    [urlParams]
  );

  const handleChangePage = useCallback(
    (newPage: number) => {
      urlParams.set('page', newPage.toString());
      updateURL();
    },
    [updateURL, urlParams]
  );

  const handleChangePageSize = useCallback(
    (newPageSize: number) => {
      urlParams.set('size', newPageSize.toString());

      handleChangePage(1);
    },
    [handleChangePage, urlParams]
  );

  const handleChangeFilter = useCallback(
    (name: string, value: string) => {
      const selectedFilter = { [name]: value };
      setFilters(selectedFilter);
      handleChangePage(1);

      urlParams.set(name, value);

      updateURL();
    },
    [handleChangePage, urlParams, updateURL]
  );

  const handleChangeFilters = useCallback(
    (newFilters: MapFilter) => {
      if (newFilters) {
        setFilters(newFilters);
        handleChangePage(1);

        Object.keys(newFilters).forEach(key => {
          urlParams.set(key, newFilters[key] as string);
        });

        updateURL();
      }
    },
    [setFilters, handleChangePage, updateURL, urlParams]
  );

  const removeFilters = useCallback(
    (names: string[]) => {
      const currentFilters = { ...filters };

      if (names) {
        names.forEach(name => {
          delete currentFilters[name];
          urlParams.delete(name);
        });
      }

      setFilters(currentFilters);

      updateURL();
    },
    [filters, urlParams, updateURL]
  );

  const removeAllFilters = useCallback(() => {
    removeFilters(Object.keys(filters));
  }, [filters, removeFilters]);

  const removeFilter = useCallback(
    (name: string) => {
      removeFilters([name]);
    },
    [removeFilters]
  );

  const requestParams: any = useMemo(() =>
    // handles pagination to be mapped as in Backend starts from index 0 so need to subtract 1
    {
      const params = {
        ...queryParams,
      };

      Object.keys(queryParams).forEach(key => {
        if (key === 'page') {
          params.page = queryParams?.page ? queryParams?.page - 1 : 0;
        }
      });

      return params;
    }, [queryParams]);

  useEffect(() => {
    Object.keys(paramObject).forEach(key => {
      if (filters[key] !== paramObject[key]) {
        setFilters({ ...filters, [key]: paramObject[key] });
      }
    });

    if (
      Object.keys(paramObject).length === 0 &&
      Object.keys(filters).length > 0
    ) {
      setFilters({});
    }
  }, [filters, paramObject, removeFilter]);

  return {
    filters,
    currentPage,
    requestParams: requestParams as QueryParameters,
    currentPageSize,
    updateURL,
    handleChangePage,
    handleChangePageSize,
    handleChangeFilter,
    handleChangeFilters,
    removeFilter,
    removeFilters,
    removeAllFilters,
  };
};

export type { UsePaginationReturn };
