import { QueryParameters } from 'types';

import { DEFAULT_PAGE_SIZE, DEFAULT_CURRENT_PAGE } from './use-pagination';

// since each entity has its own param filters so we can send supportedParamsArray or use the default of pagination
export const getQueryParams = (
  urlParams: URLSearchParams,
  supportedParamsArray?: Array<string>,
  pageSize?: number
): QueryParameters => {
  const SUPPORTED_ENTITY_LISTING_PARAMS = supportedParamsArray;

  const paramObject = Object.fromEntries(urlParams as any);

  const params: QueryParameters = Object.keys(paramObject)
    .filter(param => SUPPORTED_ENTITY_LISTING_PARAMS.includes(param))
    // make object as {page: 4}
    .map(param => ({
      [param]: paramObject[param],
    }))
    .reduce((acc, cur) => ({ ...acc, ...cur }), {});

  const keys = Object.keys(params);

  if (!keys.includes('page')) {
    params.page = DEFAULT_CURRENT_PAGE as unknown as number;
  }

  if (!keys.includes('size')) {
    params.size = pageSize || DEFAULT_PAGE_SIZE;
  }

  return params;
};
