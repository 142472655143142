import React, { useCallback } from 'react';

import { Form, Formik } from 'formik';
import { Modal, ModalButtonsFooter, Spinner } from 'workmotion-design-system';

import { en } from 'localization';
import { useRequestReturnEquipment } from 'networking/equipment-api/equipment-requests';
import {
  EquipmentReturnRequest,
  EquipmentResponseDTO,
} from 'types/equipment-types';

import {
  returnInitialValues,
  returnValidation,
  RequestReturnSchema,
} from './formik-values-setup';
import { Notes } from './notes';
import { Reason } from './reason';
import { ReturnDate } from './return-date';
import {
  ModalButtonsFooterCustomStyle,
  MainContainer,
} from './styled-components';

const {
  managedView: {
    returnEquipment: {
      title,
      submitBtn,
      cancelBtn,
      fields: { reason, returnDate, notes },
    },
  },
} = en;

export interface ModalProps<T> {
  isOpen: boolean;
  closeModal: () => void;
  selectedItem: T;
}

export const ReturnEquipment: React.FC<ModalProps<EquipmentResponseDTO>> = ({
  isOpen,
  closeModal,
  selectedItem,
}) => {
  const { isLoading, mutate } = useRequestReturnEquipment();

  const onReturnSubmit = useCallback(
    (returnData: RequestReturnSchema) => {
      const returnPlayload: EquipmentReturnRequest = {
        returnDate: returnData[returnDate.fieldName],
        reason: returnData[reason.fieldName],
        notes: returnData[notes.fieldName],
      };

      mutate(
        { equipmentId: selectedItem.equipmentId, body: returnPlayload },
        {
          onSuccess: () => {
            closeModal();
          },
        }
      );
    },
    [closeModal, mutate, selectedItem.equipmentId]
  );

  return (
    <Modal
      isOpen={isOpen}
      header={title}
      closeModal={closeModal}
      data-testid="return-equipment-modal"
    >
      <Formik
        initialValues={returnInitialValues}
        onSubmit={onReturnSubmit}
        validationSchema={returnValidation}
      >
        <Form
          style={{
            width: '100%',
            fontWeight: 400,
          }}
        >
          <MainContainer>
            <Reason />

            <ReturnDate />

            <Notes />

            <ModalButtonsFooter
              placement="space-between"
              buttonsInfo={[
                {
                  onClick: closeModal,
                  text: cancelBtn,
                  btnType: 'secondaryGrey',
                  disabled: isLoading,
                  dataCy: 'cancel-button',
                },
                {
                  type: 'submit',
                  text: isLoading ? <Spinner size={30} /> : submitBtn,
                  dataCy: 'submit-button',
                  disabled: isLoading,
                },
              ]}
              customStyle={ModalButtonsFooterCustomStyle}
            />
          </MainContainer>
        </Form>
      </Formik>
    </Modal>
  );
};
