import styled from 'styled-components';

export const Card = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '2rem 1.5rem 0rem 1.5rem',
  borderRadius: '0.5rem',
  marginBottom: '2rem',
});

export const Footer = styled.div({
  width: '28.75rem',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

export const CheckBoxContainer = styled.div({
  width: '28.75rem',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  marginBottom: '2rem',
});
