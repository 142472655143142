import { palette } from 'workmotion-design-system';

export const actionButtonStyle = {
  width: '2.5rem',
  height: '2.5rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '0.5rem',
  ':hover': {
    backgroundColor: palette.tertiary.aquaHaze,
  },
};

export const actionIconStyle = {
  color: palette.greyscale.UCLABlue,
  fontSize: '1.25rem',
  ':hover': {
    color: palette.primary.genericViridian,
  },
};
