import React, { useCallback } from 'react';

import { useField } from 'formik';
import { TextareaField } from 'workmotion-design-system';

import { en } from 'localization';

const {
  managedView: {
    returnEquipment: {
      fields: {
        notes: { fieldName, label, placeholder },
      },
    },
  },
} = en;

export const Notes: React.FC = () => {
  const [field, , { setValue }] = useField(fieldName);

  const onTextChange = useCallback(
    text => {
      setValue(text.target.value);
    },
    [setValue]
  );

  return (
    <TextareaField
      {...field}
      data-cy="notes"
      onChange={onTextChange}
      placeholder={placeholder}
      label={label}
      data-testid="notes-textarea"
    />
  );
};
