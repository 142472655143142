import React, { useCallback, useEffect, useState } from 'react';

import type { SelectOption } from 'workmotion-design-system';
import { Select } from 'workmotion-design-system';

import {
  StyledContainer,
  StyledFieldContainer,
  StyledLabel,
} from 'app/shared/styled-component';
import { en } from 'localization';

const {
  requestEquipment: {
    fields: {
      variant: { label },
    },
  },
} = en;

export const VariantSelectField: React.FC<{
  disabled?: boolean;
  options: SelectOption[];
  onSelect: (value: string) => void;
}> = ({ disabled = false, options, onSelect }) => {
  const [value, setValue] = useState<string>(options[0].value);

  const handleChange = useCallback(
    (selectedvalue: string): void => {
      setValue(selectedvalue);
      onSelect(selectedvalue);
    },
    [onSelect]
  );

  useEffect(() => {
    onSelect(options[0].value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledFieldContainer>
      <StyledLabel>{label}</StyledLabel>

      <StyledContainer data-cy="requestEquipment-rental-variant-field">
        <Select
          placeholder={options[0].label}
          attrs={{ select: { 'data-cy': 'variant-selector' } }}
          options={options}
          onChange={handleChange}
          inputProps={{ id: 'requestEquipment-rental-variant-field' }}
          disabled={disabled}
          value={value}
        />
      </StyledContainer>
    </StyledFieldContainer>
  );
};
