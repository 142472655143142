import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

export const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '2rem 2rem',
  width: '28.75rem',
  borderRadius: '0.5rem',
  backgroundColor: palette.secondary.white,
  marginBottom: '2rem',
});

export const ProductInfoAndPiceContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignContent: 'center',
  alignItems: 'center',
  width: '100%',
  marginBottom: '2rem',
});

export const ProductInfo = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: '0.5rem',
  alignContent: 'baseline',
  justifyContent: 'flex-start',
});

export const ProductImage = styled.img({
  width: '4.625rem',
  height: '4.625rem',
});

export const ProductNameAndBrandContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '2rem',
  gap: '1.3rem',
});

export const ProductName = styled.p({
  width: '10rem',
  fontWeight: 600,
  fontSize: '0.875rem',
  color: palette.greyscale.persianBlue,
});

export const ProductBrand = styled.p({
  fontweight: 400,
  fontSize: '0.875rem',
  color: palette.greyscale.UCLABlue,
});
