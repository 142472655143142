import React, { useState, useCallback } from 'react';

import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import { InputField, Avatar, palette } from 'workmotion-design-system';

import { en } from 'localization';
import { EmployeeResponseDTO } from 'types/employee';

import {
  autocompleteOptionStyles,
  autoCompleteStyles,
  StyledPaper,
} from './styled-components';

const {
  talentSearch: { placeholder },
} = en;

interface SearchAutocompleteProps {
  onSelect?: (employee: EmployeeResponseDTO) => void;
  onRemove?: () => void;
  options?: EmployeeResponseDTO[];
  selectedTalent?: EmployeeResponseDTO;
  isDisabled?: boolean;
  setSearchValue: (value: string) => void;
  loadMoreOptions: () => void;
  isLoading: boolean;
  withSearchIcon?: boolean;
  showError?: boolean;
}

export const SearchAutocomplete: React.FC<SearchAutocompleteProps> = ({
  onSelect,
  onRemove,
  options,
  setSearchValue,
  selectedTalent = null,
  isDisabled = false,
  loadMoreOptions,
  isLoading,
  withSearchIcon = false,
  showError = false,
}) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  const onInputChange = useCallback(
    v => {
      if (!v?.target.value) {
        return;
      }

      if (v?.target.value.length < 3 && isOpen) {
        setOpen(false);

        return;
      }

      if (v?.target?.value.length >= 3) {
        setSearchValue(v.target.value);

        if (!isOpen) {
          setOpen(true);
        }
      }
    },
    [isOpen, setSearchValue]
  );

  const getOptionLabel = useCallback(
    (option: EmployeeResponseDTO) => `${option.lastName} ${option.firstName}`,
    []
  );

  const isOptionEqualToValue = useCallback(
    (option: EmployeeResponseDTO, value: EmployeeResponseDTO) =>
      option.id === value.id,
    []
  );

  const onValueSelect = useCallback(
    (_event, value) => {
      if (value) {
        onSelect(value);
      } else {
        onRemove();
      }
    },
    [onSelect, onRemove]
  );

  const renderOption = useCallback(
    (props, option) => (
      <Box
        component="li"
        {...props}
        key={option.id}
        sx={autocompleteOptionStyles}
        data-testid={`${option.lastName}-option`}
        data-cy={`${option.lastName}-option`}
      >
        <Avatar
          text={{ title: `${option.firstName} ${option.lastName}` }}
          size="small"
        />
      </Box>
    ),
    []
  );

  const [isScrollBottom, setIsScrollBottom] = useState<boolean>(false);

  const nearBottom = useCallback(
    (
      target: {
        scrollHeight?: number;
        scrollTop?: number;
        clientHeight?: number;
      } = {}
    ) => {
      const diff = Math.round(target.scrollHeight - target.scrollTop);

      return diff - 25 <= target.clientHeight;
    },
    []
  );

  const handleScroll = useCallback(
    ({ target }) => {
      setIsScrollBottom(nearBottom(target));
    },
    [setIsScrollBottom, nearBottom]
  );

  React.useEffect(() => {
    loadMoreOptions();
  }, [isScrollBottom, loadMoreOptions]);

  return (
    <Autocomplete
      value={selectedTalent}
      id="talent-search"
      options={options}
      open={isOpen}
      autoHighlight
      renderOption={renderOption}
      onInputChange={onInputChange}
      getOptionLabel={getOptionLabel}
      PaperComponent={StyledPaper}
      sx={autoCompleteStyles}
      ListboxProps={{
        onScroll: handleScroll,
      }}
      onClose={() => {
        setOpen(false);
      }}
      filterOptions={opts => opts}
      onChange={onValueSelect}
      loading={isLoading}
      isOptionEqualToValue={isOptionEqualToValue}
      popupIcon={''}
      disabled={isDisabled}
      size="small"
      renderInput={params => {
        if (withSearchIcon) {
          params.InputProps.startAdornment = (
            <InputAdornment position="start">
              <SearchRoundedIcon
                sx={{
                  fontSize: '1rem',
                  color: palette.greyscale.grainOfSalt,
                }}
              />
            </InputAdornment>
          );
        }

        return (
          <InputField
            {...params}
            placeholder={placeholder}
            error={showError}
            attrs={{ input: { 'data-cy': 'talent-search' } }}
          />
        );
      }}
    />
  );
};
