import React, { useState, useEffect, useCallback } from 'react';

import { TalentSearch as NewTalentFilter } from 'app/shared/talent-search';
import { useTalentDetails } from 'networking/employee-api/employee-network-requests';
import { EmployeeResponseDTO } from 'types/employee';

import { TalentSearchWrapper } from './styled-components';

export const TalentSearch: React.FC<{
  talentId?: string;
  onTalentSelect: (talentId: string) => void;
  onTalentRemove: () => void;
}> = ({ talentId, onTalentSelect, onTalentRemove }) => {
  const [talent, setTalent] = useState<EmployeeResponseDTO>();
  const { data } = useTalentDetails(talentId);

  useEffect(() => {
    if (talentId && !talent) {
      setTalent(data);
    }
  }, [talentId, talent, data]);

  const onSelect = useCallback(
    (selectedTalent: EmployeeResponseDTO) => {
      if (!selectedTalent) {
        setTalent(null);
        onTalentRemove();
      }
      setTalent(selectedTalent);
      onTalentSelect(selectedTalent.id);
    },
    [setTalent, onTalentSelect, onTalentRemove]
  );

  const onRemove = useCallback(() => {
    setTalent(null);
    onTalentRemove();
  }, [setTalent, onTalentRemove]);

  return (
    <TalentSearchWrapper>
      <NewTalentFilter
        onSelect={onSelect}
        withSearchIcon
        selectedTalent={talent}
        onRemove={onRemove}
      />
    </TalentSearchWrapper>
  );
};
