import * as yup from 'yup';

import { en } from 'localization';

const {
  requestEquipment: {
    fields: { talent, productName },
    validationMessages: { productNameValidationMessage },
  },
} = en;

export const requestEquipmentInitialValues = () => ({
  [talent.fieldName]: '',
  [productName.fieldName]: [],
});

export const requestEquipmentSchema = yup.object().shape({
  [productName.fieldName]: yup.array().when(['talent'], {
    is: val => val != '',
    then: yup.array().of(yup.object()).min(1, productNameValidationMessage),
  }),
});
