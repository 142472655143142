import React from 'react';

import {
  PreviewItemContainer,
  PreviewLabel,
  PreviewValue,
} from './styled-components';

export const PreviewItem: React.FC<{
  label: string;
  value?: string | React.ReactElement;
  attrs?: {
    item?: Record<string, string>;
    label?: Record<string, string>;
    value?: Record<string, string>;
  };
  containerStyle?: React.CSSProperties;
}> = ({ label, value, attrs, containerStyle }) => (
  <PreviewItemContainer style={containerStyle} {...attrs?.item}>
    <PreviewLabel {...attrs?.label}>{label}</PreviewLabel>

    <PreviewValue {...attrs?.value}>{value || '-'}</PreviewValue>
  </PreviewItemContainer>
);
