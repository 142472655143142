import React from 'react';

import { Popover } from '@mui/material';

import {
  StyledDropdown,
  popoverStyle,
  StyledDropdownItem,
} from './styled-components';

export interface StyledDropdownItemProps {
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  disabled?: boolean;
}

export interface DropdownItemProps extends StyledDropdownItemProps {
  title: string | JSX.Element;
  icon?: JSX.Element;
  dataCy?: string;
}

export interface DropdownProps {
  dropdownController: JSX.Element | string;
  dropdownItems: DropdownItemProps[];
}

const DropdownItem: React.FC<DropdownItemProps> = ({
  onClick,
  title,
  icon,
  disabled = false,
  dataCy = null,
}) => (
  <StyledDropdownItem
    disabled={disabled}
    onClick={disabled ? null : onClick}
    data-cy={dataCy}
  >
    {icon}

    {title}
  </StyledDropdownItem>
);

// TODO: Replace it with the implementation from the design-system or mui
const Dropdown: React.FC<DropdownProps> = ({
  dropdownController,
  dropdownItems,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const isOpen = Boolean(anchorEl);

  const onCloseEvent = () => {
    setAnchorEl(null);
  };

  return (
    <StyledDropdown>
      <div
        role="button"
        tabIndex={0}
        onClick={handleClick}
        onKeyDown={handleClick}
      >
        {dropdownController}
      </div>

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={onCloseEvent}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={popoverStyle}
        data-testid="action-popover"
      >
        {dropdownItems.map((dropdownItem, i) => (
          <DropdownItem
            key={`dropdown-item-${i}`}
            onClick={event => {
              dropdownItem.onClick(event);
              onCloseEvent();
            }}
            title={dropdownItem.title}
            icon={dropdownItem.icon}
            dataCy={dropdownItem.dataCy}
          />
        ))}
      </Popover>
    </StyledDropdown>
  );
};

export { Dropdown };
