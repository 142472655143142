import React, { useState, useEffect, useCallback } from 'react';

import { FiltersMenu } from 'app/shared/filters-menu';
import { StatusFilter } from 'app/shared/filters-menu/status-filter';
import {
  AuthenticatedIdentity,
  useUserIdentity,
} from 'authentication/use-user-identity';
import {
  GetEquipmentQueryParameters,
  ActiveStateStatus,
  ReturnStateStatus,
} from 'types/equipment-types';

import { ExportReportBtn } from './export-report-btn';
import { StyledFilterPanel } from './styled-components';
import { TalentSearch } from './talent-search';

export const FilterPanel: React.FC<{
  filters: GetEquipmentQueryParameters;
  changeFilter: (filters: any) => void;
  removeFilter: (name: string[]) => void;
  isActive: boolean;
}> = ({ filters, changeFilter, removeFilter, isActive }) => {
  const [localFilters, setLocalFilters] = useState<GetEquipmentQueryParameters>(
    {}
  );

  const { isAdmin } = useUserIdentity() as AuthenticatedIdentity;

  useEffect(() => {
    setLocalFilters(filters);
  }, [filters]);

  const handleFilterStatusChange = useCallback(
    (newState: string) => {
      if (isActive) {
        setLocalFilters({
          ...filters,
          activeStatus: newState as ActiveStateStatus,
        });
      } else {
        setLocalFilters({
          ...filters,
          returnStatus: newState as ReturnStateStatus,
        });
      }
    },
    [filters, isActive]
  );

  const handleOnApply = useCallback(() => {
    changeFilter(localFilters);
  }, [changeFilter, localFilters]);

  const handleOnReset = useCallback(() => {
    setLocalFilters({});
    removeFilter(['activeStatus', 'returnStatus']);
  }, [removeFilter]);

  const onTalentSelect = useCallback(
    (selectedTalent: string) => {
      changeFilter({ talentId: selectedTalent });
    },
    [changeFilter]
  );

  const onTalentRemove = useCallback(() => {
    removeFilter(['talentId']);
  }, [removeFilter]);

  return (
    <StyledFilterPanel data-testid="filter-panel">
      <TalentSearch
        talentId={filters?.talentId}
        onTalentSelect={onTalentSelect}
        onTalentRemove={onTalentRemove}
      />

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '1rem',
        }}
      >
        {isAdmin && (
          <ExportReportBtn queryParams={filters} isActive={isActive} />
        )}

        <FiltersMenu
          onReset={handleOnReset}
          onApply={handleOnApply}
          dataCyReset="equipment-filter-reset"
          dataCyApply="equipment-filter-apply"
        >
          <StatusFilter
            value={
              isActive ? localFilters?.activeStatus : localFilters?.returnStatus
            }
            active={isActive}
            onChange={handleFilterStatusChange}
          />
        </FiltersMenu>
      </div>
    </StyledFilterPanel>
  );
};
