import React, { FC, useCallback } from 'react';

import type { SelectOption } from 'workmotion-design-system';
import { SelectAutocomplete } from 'workmotion-design-system';

import { en } from 'localization';

export interface CategoriesListProps {
  options?: SelectOption[];
  onValueSelected?: (value: string) => void;
  value?: string;
}

export const CategoriesList: FC<CategoriesListProps> = ({
  options,
  onValueSelected,
  value,
}) => {
  const {
    filtersMenu: { categoryPlaceHolder },
  } = en;

  const sortedOptions = options
    ? [...options].sort((a, b) => a.label.localeCompare(b.label))
    : [];

  const onChange = useCallback(
    (selectedValue: string): void => {
      onValueSelected(selectedValue);
    },
    [onValueSelected]
  );

  return (
    <SelectAutocomplete
      value={value}
      onChange={onChange}
      options={sortedOptions}
      placeholder={categoryPlaceHolder}
      data-testid="category-list"
      data-cy="category-list"
    ></SelectAutocomplete>
  );
};
