import React, { useState } from 'react';

import { Pagination, Spinner } from 'workmotion-design-system';

import { useSelectedCompanyId } from 'app/manage-view/helpers/use-company-id';
import { CenterPositionStyle } from 'app/shared/styled-component';
import {
  AuthenticatedIdentity,
  useUserIdentity,
} from 'authentication/use-user-identity';
import { useActiveEquipmentRequests } from 'networking/equipment-api/equipment-requests';
import {
  EquipmentQueryParameters,
  EquipmentResponseDTO,
} from 'types/equipment-types';

import { ReturnTable } from './return-table';
import {
  DEFAULT_PAGE_SIZE_OPTIONS,
  usePagination,
} from '../../../helpers/use-pagination';
import { EmptyList } from '../empty-list';
import { FilterPanel } from '../filter-panel/filter-panel';
import { PreviewEquipment } from '../preview-equipment';

export const ReturnView: React.FC = () => {
  const { companyId: userCompanyId } =
    useUserIdentity() as AuthenticatedIdentity;

  const { selectedCompanyId } = useSelectedCompanyId();

  const {
    filters,
    currentPage,
    requestParams,
    currentPageSize,
    handleChangePage,
    handleChangePageSize,
    handleChangeFilters,
    removeFilters,
  } = usePagination();

  const { data: returnedEquipmentList, isLoading } = useActiveEquipmentRequests(
    {
      state: 'RETURN',
      companyId: selectedCompanyId || userCompanyId || null,
      direction: 'desc',
      ...(requestParams as EquipmentQueryParameters),
    }
  );

  const [isPreviewReturnOpen, setPreviewReturn] = useState<boolean>(false);

  const [selectedReturnEquipment, setSelectedReturnEquipment] =
    useState<EquipmentResponseDTO>();

  if (isLoading) {
    return (
      <div style={CenterPositionStyle}>
        <Spinner data-testid="equipment-list-spinner" />
      </div>
    );
  }

  return (
    <div data-testid="Return-view-component" style={{ paddingBottom: '2rem' }}>
      <FilterPanel
        filters={filters}
        changeFilter={handleChangeFilters}
        removeFilter={removeFilters}
        isActive={false}
      />

      {returnedEquipmentList?.content.length > 0 ? (
        <>
          <ReturnTable
            equipmentList={returnedEquipmentList.content}
            setSelectedEquipment={setSelectedReturnEquipment}
            openPreviewModal={setPreviewReturn}
          />

          <Pagination
            total={returnedEquipmentList?.page.totalElements}
            currentPage={currentPage}
            onChange={handleChangePage}
            pageSize={currentPageSize}
            onPageSizeChanged={handleChangePageSize}
            pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
          />
        </>
      ) : (
        <EmptyList />
      )}

      <PreviewEquipment
        isOpen={isPreviewReturnOpen}
        closeModal={() => {
          setPreviewReturn(false);
        }}
        selectedEquipment={selectedReturnEquipment}
      />
    </div>
  );
};
