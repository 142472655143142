import React from 'react';

import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { en } from 'localization';
import { RequestError } from 'types';

const {
  generic: { genricError },
} = en;

const handleRequestError = (): void => {
  toast(genricError, {
    type: 'error',
  });
};

const errorHandler = (error: RequestError) => {
  Sentry.captureException(error);

  if (error?.response?.status === 401 || error?.response?.status === 403) {
    window.location.href = '/';
  } else handleRequestError();
};

export const workMotiondefaultOptions = {
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 60 * 60 * 1000,
      refetchOnWindowFocus: false,
      onError(error: RequestError) {
        errorHandler(error);
      },
    },
    mutations: {
      onError(error: RequestError) {
        errorHandler(error);
      },
    },
  },
};

export const queryClient = new QueryClient(workMotiondefaultOptions);

export const NetworkContext = ({ children }) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);
