import React, { useCallback } from 'react';

import { useField } from 'formik';
import { DatePicker, FormField } from 'workmotion-design-system';

import { en } from 'localization';

const {
  managedView: {
    returnEquipment: {
      fields: {
        returnDate: { fieldName, label },
      },
    },
  },
} = en;

const getFormattedDate = (date: Date): string => {
  if (date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  return null;
};

export const ReturnDate: React.FC = () => {
  const [field, meta, { setValue }] = useField(fieldName);
  const { value } = field;
  const showError = !!(meta.touched && meta.error);

  const handleChange = useCallback(
    newSelected => {
      const selectedDate = getFormattedDate(newSelected);
      setValue(selectedDate);
    },
    [setValue]
  );

  return (
    <FormField
      label={label}
      errorMessage={meta.error}
      showError={showError}
      required
      attrs={{
        label: {
          'data-cy': 'return-date-label',
          'data-testid': 'return-date-picker-label',
        },
        error: {
          'data-cy': 'return-date-errormsg',
        },
      }}
    >
      <DatePicker
        {...field}
        id="return-date"
        minDate={new Date()}
        hasError={!!showError}
        onChange={handleChange}
        value={(value && new Date(value)) || null}
        dataCy="return-date-picker"
      />
    </FormField>
  );
};
