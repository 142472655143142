import { QueryParameters } from 'types';

import { createClient } from './open-api-client';

export async function fetchAllEmployees(
  token: string,
  params?: QueryParameters
) {
  const client = await createClient(token);

  const response = await client.getAllEmployees(params as any);

  return response.data;
}

export async function getTalentDetails(talentId: string, token: string) {
  const client = await createClient(token);

  const response = await client.getEmployee({ employeeId: talentId });

  return response.data;
}
