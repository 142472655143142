import React from 'react';

import { Avatar } from 'workmotion-design-system';

import { EquipmentEmployeeDTO } from 'types/equipment-types';

export const TalentName: React.FC<{ talent: EquipmentEmployeeDTO }> = ({
  talent,
}) => (
  <Avatar
    text={{
      title: `${talent.firstName.replaceAll(
        '-',
        ''
      )} ${talent.lastName.replaceAll('-', '')}`,
      placeholder: `${talent.firstName} ${talent.lastName}`,
    }}
    size="small"
    key={talent.id}
  ></Avatar>
);
